import _ from "lodash";

export const LOAD_SONG = "LOAD_SONG";
export const UPDATE_ID = "UPDATE_ID";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_SONG_STATE = "UPDATE_SONG_STATE";
export const ADD_SAMPLE = "ADD_SAMPLE";
export const UPDATE_SAMPLE = "UPDATE_SAMPLE";
export const DELETE_SAMPLE = "DELETE_SAMPLE";
export const UPDATE_SLUG = "UPDATE_SLUG";
export const UPDATE_PATTERN = "UPDATE_PATTERN";
export const DELETE_PATTERN = "DELETE_PATTERN";
export const UDPATE_BEATS = "UPDATE_BEATS";

export const loadSong = (song, songFromDatabase) => {
  return songFromDatabase;
};

export const updateId = (song, id) => {
  const newSong = _.cloneDeep(song);
  newSong.id = id;
  return newSong;
};

export const updateTitle = (song, title) => {
  const newSong = _.cloneDeep(song);
  newSong.title = title;
  return newSong;
};

export const updateSongState = (song, songStateUpdate) => {
  const newState = { ...song.state, ...songStateUpdate.state };

  return { ...song, state: { ...newState } };
};

export const addSample = (song, sample, defaultSampleValues) => {
  const { samples } = song.state;
  const newSample = { ...defaultSampleValues, ...sample };
  const newSamples = [...samples, newSample];
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};

export const updateSample = (song, sampleUpdate) => {
  const { samples } = song.state;
  const sample = samples.find(sample => sample.id === sampleUpdate.id);
  const newSample = { ...sample, ...sampleUpdate };
  const newSamples = [...samples];
  newSamples[sampleUpdate.id] = newSample;
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};

export const deleteSample = (song, id) => {
  const { samples } = song.state;
  const newSamples = samples.filter(sample => sample.id != id);
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};

export const updateSlug = (song, slug) => {
  const newSong = _.cloneDeep(song);
  newSong.slug = slug;
  return newSong;
};

export const updatePattern = (song, { sampleId, patternId, update }) => {
  const { samples } = song.state;
  const sample = samples.find(sample => sample.id === sampleId);
  const pattern = sample.patterns[patternId];

  const newSample = { ...sample };
  const newPatterns = [...sample.patterns];
  const newPattern = { ...pattern, ...update };
  newPatterns[patternId] = newPattern;
  newSample.patterns = newPatterns;

  const newSamples = [...samples];
  newSamples[sampleId] = newSample;
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};

export const deletePattern = (song, { sampleId, patternId }) => {
  const { samples } = song.state;
  const sample = samples.find(sample => sample.id === sampleId);
  const patterns = sample.patterns;
  const newPatterns = patterns
    .slice(0, patternId)
    .concat(patterns.slice(patternId + 1, patterns.length));

  const newSample = { ...sample };
  newSample.patterns = newPatterns;

  const newSamples = [...samples];
  newSamples[sampleId] = newSample;
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};

export const updateBeats = (song, { sampleId, newBeats }) => {
  const { samples } = song.state;
  const sample = samples.find(sample => sample.id === sampleId);
  const newSample = { ...sample };

  newSample.beats = newBeats;
  const newSamples = [...samples];
  newSamples[sampleId] = newSample;
  const newState = { ...song.state, ...{ samples: newSamples } };

  return { ...song, state: { ...newState } };
};
