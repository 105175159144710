import React from "react";
import PropTypes from "prop-types";

import { PLAYING_SAMPLE, PLAYING_SEQUENCE, PAUSED } from "../common/index";

const SampleTransport = ({
  id,
  playbackStatus,
  looping,
  onPlaySample,
  onPlaySequence,
  onPause,
  onStop,
  onLoop,
  onDeleteSample
}) => {
  return (
    <div className="sample-transport-container">
      <button
        className={
          "play-sample playback-control-vertical" +
          (playbackStatus === PLAYING_SAMPLE ? " button-success" : "")
        }
        data-icon="e"
        onClick={onPlaySample}
      />
      <button
        className={
          "play-sequence playback-control-vertical" +
          (playbackStatus === PLAYING_SEQUENCE ? " button-success" : "")
        }
        data-icon="a"
        onClick={onPlaySequence}
      />
      <button
        className={
          "pause playback-control-vertical" +
          (playbackStatus === PAUSED ? " button-success" : "")
        }
        data-icon="d"
        onClick={onPause}
      />
      <button
        className="stop playback-control-vertical"
        data-icon="b"
        onClick={onStop}
      />
      <button
        className={
          "loop playback-control-vertical" + (looping ? " button-success" : "")
        }
        data-icon="c"
        onClick={onLoop}
      />
      <button
        className="delete-sample playback-control-vertical"
        data-icon="g"
        onClick={() => onDeleteSample(id)}
      />
    </div>
  );
};

SampleTransport.propTypes = {
  id: PropTypes.number,
  playbackStatus: PropTypes.string,
  looping: PropTypes.bool,
  onPlaySample: PropTypes.func,
  onPlaySequence: PropTypes.func,
  onPause: PropTypes.func,
  onStop: PropTypes.func,
  onLoop: PropTypes.func,
  onDeleteSample: PropTypes.func
};

export default SampleTransport;
