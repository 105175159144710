import { options } from "./fetch";

const uploadFile = async (jwtToken, userId, songId, file) => {
  let data = new FormData();
  data.append("file", file);

  const fetchUrl = `${process.env.API_BASE_URL}/api/users/${userId}/songs/${songId}/uploads`;
  const fetchParams = {
    ...options("POST", jwtToken, true),
    body: data,
  };

  const res = await fetch(fetchUrl, fetchParams);

  const { filepath } = await res.json();

  return filepath;
};

export default uploadFile;
