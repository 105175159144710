import { options } from "./fetch";

export const signup = async (email, password) => {
  const data = { email, password };

  const fetchUrl = `${process.env.API_BASE_URL}/api/users`;
  const fetchParams = {
    ...options("POST"),
    body: JSON.stringify(data),
  };

  const res = await fetch(fetchUrl, fetchParams)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) console.error(res.error);
      return res;
    });

  const { user, token } = res;

  return { user, token };
};
