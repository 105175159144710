import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  previewSequence,
  cancelPreviewSequence,
  addSequence,
  removeSequence,
  updateSequences,
  updateNumberOfBeats,
  sequenceFits,
  isSequenceBeat,
  isOutOfRange
} from "../utils/beat-utils";
import {
  findSequenceStartIndex,
  findSequenceEndIndex,
  findAllIndices
} from "../utils/sequence-utils";

import {
  REST,
  SEQUENCE_START,
  SEQUENCE,
  SEQUENCE_END,
  SEQUENCE_PREVIEW
} from "../common/index";

const Tracks = ({
  lightColors,
  darkColors,
  regionColors,
  samples,
  numberOfBeats,
  currentBeat,
  onUpdateSample
}) => {
  const sequences = samples.map(sample =>
    sample.patterns.map(pattern => pattern.sequence)
  );

  const beats = samples.map(sample =>
    sample.patterns.map(pattern => pattern.beats)
  );
  const canvasElement = useRef(null);

  const handleBeatMouseOver = e => {
    const startIndex = parseInt(e.target.getAttribute("data-index"));
    const currentBeat = beats[startIndex];

    if (!sequenceFits({ beats, startIndex, sequenceLength })) return;

    const newBeats = previewSequence({
      patternId,
      beats,
      startIndex,
      sequence
    });
    onUpdateSample({ id, beats: newBeats });
  };

  const handleBeatMouseLeave = e => {
    const beatIndex = parseInt(e.target.getAttribute("data-index"));

    if (beats[beatIndex].type === SEQUENCE) return;
    if (!sequenceFits({ beats, beatIndex, sequenceLength })) return;

    const newBeats = cancelPreviewSequence({
      patternId,
      beats,
      startIndex,
      sequence
    });
    onUpdateSample({ id, beats: newBeats });
  };

  useEffect(() => {
    const canvas = canvasElement.current;
    const context = canvas.getContext("2d", { alpha: true });
    canvas.width = canvas.clientWidth;
    let hoveredBeatsBySample = new Array(samples.length).fill([]);
    let markerHoverId = undefined;

    const buildMarkerRects = () => {
      const markerRects = [];
      for (let i = 0; i < numberOfBeats; i++) {
        markerRects.push({
          x: (i * canvas.width) / numberOfBeats,
          y: 0,
          width: markerWidth,
          height: markerHeight
        });
      }

      return markerRects;
    };

    const buildBeatRects = sampleId => {
      const beatRects = [];
      for (let i = 0; i < numberOfBeats; i++) {
        beatRects.push({
          x: (i * canvas.width) / numberOfBeats,
          y: sampleId + sampleId * 50,
          width: canvas.width / numberOfBeats,
          height: 50
        });
      }

      return beatRects;
    };

    const handleBeatClick = ({
      sampleId,
      beats,
      startIndex,
      sequence,
      patternId
    }) => {
      if (!sequenceFits({ beats, startIndex, sequenceLength: sequence.length }))
        return;

      const newBeats = addSequence({
        patternId,
        beats,
        startIndex,
        sequence
      });
      onUpdateSample({ id: sampleId, beats: newBeats });
    };

    const handleBeatRightClick = ({
      sampleId,
      beats,
      beatIndex,
      sequenceLength
    }) => {
      const currentBeatType = beats[beatIndex].type;
      if (currentBeatType === REST || currentBeatType === SEQUENCE_PREVIEW) {
        return;
      }

      const newBeats = removeSequence({
        beats,
        startIndex: findSequenceStartIndex(beats, beatIndex),
        sequenceLength
      });

      onUpdateSample({ id: sampleId, beats: newBeats });
    };

    const hoveredBeatsOverlapSequence = ({ beats, hoveredBeats }) => {
      const overlaps = hoveredBeats.filter(beatId => {
        return (
          !isOutOfRange(beatId, beats.length) &&
          isSequenceBeat(beats[beatId].type)
        );
      });
      return overlaps.length > 0;
    };

    const isValidPlacement = ({ beats, hoveredBeats, beatIndex }) => {
      if (hoveredBeats.length === 0) return true;
      if (!hoveredBeats.includes(beatIndex)) return true;
      return !hoveredBeatsOverlapSequence({ beats, hoveredBeats });
    };

    const beatFillStyle = ({
      sampleId,
      beat,
      hoveredSequenceIds,
      isValidPlacement,
      beatIndex,
      hoveredBeats,
      darkColor,
      lightColor,
      sequence
    }) => {
      if (!hoveredSequenceIds.length && !isValidPlacement) {
        return "#af5a57";
      } else if (
        !hoveredSequenceIds.length &&
        hoveredBeats.includes(beatIndex)
      ) {
        return lightColor;
      } else if (isSequenceBeat(beat.type)) {
        if (sequence) {
          let color = darkColor;
          if (beat.regionId != REST) color = regionColors[beat.regionId];
          return color;
        } else {
          return lightColor;
        }
      } else {
        return darkColor;
      }
    };

    const renderBeat = ({
      beatIndex,
      sampleId,
      x,
      y,
      width,
      height,
      beats,
      hoveredBeats,
      hoveredSequenceIds,
      sequence
    }) => {
      // RENDER SEQUENCE TOP BAR
      context.fillStyle = beatFillStyle({
        sampleId,
        beat: beats[beatIndex],
        hoveredSequenceIds,
        isValidPlacement: isValidPlacement({
          beats,
          hoveredBeats,
          beatIndex
        }),
        beatIndex,
        hoveredBeats,
        darkColor: darkColors[sampleId],
        lightColor: lightColors[sampleId]
      });
      context.fillRect(x, y, width, 35);
      // RENDER REGION COLORED SEQUENCE BEATS
      context.fillStyle = beatFillStyle({
        sampleId,
        beat: beats[beatIndex],
        hoveredSequenceIds,
        isValidPlacement: isValidPlacement({
          beats,
          hoveredBeats,
          beatIndex
        }),
        beatIndex,
        hoveredBeats,
        darkColor: darkColors[sampleId],
        lightColor: lightColors[sampleId],
        sequence
      });

      // RENDER DIVIDER LINE BETWEEN SEQUENCE TOP BAR AND SEQUENCE BEATS
      context.fillRect(x, y + 35, width, 15);
      context.fillStyle = "#252525";
      context.fillRect(x, y + 35, 1, 15);
      const previousBeat = beats[beatIndex - 1];
      if (
        (previousBeat &&
          ![SEQUENCE, SEQUENCE_START].includes(previousBeat.type)) ||
        (previousBeat &&
          previousBeat.type === SEQUENCE_START &&
          beats[beatIndex].type === SEQUENCE_START)
      ) {
        // add borders unless we're painting a sequence or currentBeat
        context.fillStyle = "#252525";
        context.fillRect(x, y, 1, height);
      }
      if (
        // add sequence visualization borders if we're painting a sequence
        isSequenceBeat(beats[beatIndex].type)
      ) {
        context.fillStyle = "#252525";
        context.fillRect(x, y + 35, width, 1);
      }

      // HIGHLIGHT HOVERED SEQUENCE
      if (hoveredSequenceIds.includes(beatIndex)) {
        context.fillStyle = "rgba(255, 255, 255, 0.1)";
        context.fillRect(x, y, width, 50);
      }

      // HIGHLIGHT CURRENT BEAT
      if (beatIndex === currentBeat) {
        context.fillStyle = "rgba(92, 183, 113, 0.3)";
        context.fillRect(
          x,
          0,
          width,
          50 * samples.length + samples.length + markerHeight
        );
      }

      // HIGHLIGHT CURRENT BEAT
      if (beatIndex === markerHoverId) {
        context.fillStyle = "rgba(255, 255, 255, 0.1)";
        context.fillRect(
          x,
          0,
          width,
          50 * samples.length + samples.length + markerHeight
        );
      }

      // LABELS
      if (beats[beatIndex].type === SEQUENCE_START) {
        context.fillStyle = "#fff";
        context.font = "16px Muli-Light";
        context.fillText(`${beats[beatIndex].patternId + 1}`, x + 5, 20 + y);
      }
    };

    const renderSong = hoveredBeatsBySample => {
      for (let beatIndex = 0; beatIndex < numberOfBeats; beatIndex++) {
        // RENDER MARKER
        const x = (beatIndex * canvas.width) / numberOfBeats;
        const y = 0;
        context.fillStyle = "#353535";
        context.fillRect(x, y, markerWidth, markerHeight);
        context.fillStyle = "#252525";
        context.fillRect(x, y, 1, markerHeight);

        // RENDER MARKER LABEL FOR PREVIOUS BEAT SO IT ISN'T DRAWN OVER BY NEXT BEAT
        if (beatIndex % 4 === 0) {
          context.fillStyle = "#fff";
          context.font = "12px Muli-Light";
          context.fillText(beatIndex, x - 17, 15);
        }

        samples.map((sample, sampleId) => {
          const { beats, patterns, selectedPatternId } = sample;
          const sequence = patterns[selectedPatternId].sequence;
          const patternId = selectedPatternId;
          const sequenceLength = sequence.length;
          const hoveredBeats = hoveredBeatsBySample[sampleId];
          let hoveredSequenceIds = [];

          if (hoveredBeats.length) {
            const cursorIndex = hoveredBeats[0];
            if (isSequenceBeat(beats[cursorIndex].type)) {
              const startIndex = findSequenceStartIndex(beats, cursorIndex);
              const endIndex = findSequenceEndIndex(beats, cursorIndex);
              for (let i = startIndex; i <= endIndex; i++) {
                hoveredSequenceIds.push(i);
              }
            }
          }

          const x = (beatIndex * canvas.width) / numberOfBeats;
          const y = sampleId + markerHeight + sampleId * 50;
          const width = canvas.width / numberOfBeats;
          const height = 50;

          renderBeat({
            beatIndex,
            sampleId,
            x,
            y,
            width,
            height,
            beats,
            hoveredBeats,
            hoveredSequenceIds,
            sequence
          });
        });
      }
    };

    const isMouseOverBeat = (x, y, beat) => {
      const result =
        x >= beat.x &&
        x <= beat.x + beat.width &&
        y >= beat.y + markerHeight &&
        y <= beat.y + beat.height + markerHeight;
      return result;
    };

    const isMouseOverMarker = (x, y, marker) => {
      const result =
        x >= marker.x &&
        x <= marker.x + marker.width &&
        y >= marker.y &&
        y <= marker.y + marker.height;
      return result;
    };

    canvas.onmousemove = e => {
      const rect = canvas.getBoundingClientRect();
      const canvasX = e.clientX - rect.left;
      const canvasY = e.clientY - rect.top;
      hoveredBeatsBySample = hoveredBeatsBySample.map(sample => []);
      markerHoverId = undefined;

      context.clearRect(0, 0, canvas.width, canvas.height);

      samples.map((sample, sampleIndex) => {
        const { patterns, selectedPatternId } = sample;
        const pattern = patterns[selectedPatternId];
        const beatRects = buildBeatRects(sampleIndex);
        const markerRects = buildMarkerRects();

        for (let i = beatRects.length - 1, beat; (beat = beatRects[i]); i--) {
          if (isMouseOverMarker(canvasX, canvasY, markerRects[i])) {
            markerHoverId = i;
          }

          if (isMouseOverBeat(canvasX, canvasY, beat)) {
            hoveredBeatsBySample[sampleIndex] = Array.from(
              { length: pattern.sequence.length },
              (v, j) => j + i
            );

            hoveredBeatsBySample[sampleIndex].push(i);
          } else if (hoveredBeatsBySample[sampleIndex].includes(i)) {
            hoveredBeatsBySample[sampleIndex].push(i);
          }
        }
      });

      context.clearRect(0, 0, canvas.width, canvas.height);
      renderSong(hoveredBeatsBySample);
    };

    canvas.onclick = e => {
      samples.map((sample, sampleId) => {
        const { beats, patterns, selectedPatternId } = sample;
        const pattern = patterns[selectedPatternId];

        const rect = canvas.getBoundingClientRect();
        const canvasX = e.clientX - rect.left;
        const canvasY = e.clientY - rect.top;
        const beatRects = buildBeatRects(sampleId);

        for (let i = beatRects.length - 1, beat; (beat = beatRects[i]); i--) {
          if (isMouseOverBeat(canvasX, canvasY, beat)) {
            handleBeatClick({
              sampleId,
              beats,
              startIndex: i,
              sequence: pattern.sequence,
              patternId: selectedPatternId
            });
          }
        }
      });
    };

    canvas.oncontextmenu = e => {
      e.preventDefault();
      samples.map((sample, sampleId) => {
        const { beats, patterns, selectedPatternId } = sample;
        const pattern = patterns[selectedPatternId];
        const rect = canvas.getBoundingClientRect();
        const canvasX = e.clientX - rect.left;
        const canvasY = e.clientY - rect.top;
        const beatRects = buildBeatRects(sampleId);

        for (let i = beatRects.length - 1, beat; (beat = beatRects[i]); i--) {
          if (isMouseOverBeat(canvasX, canvasY, beat)) {
            handleBeatRightClick({
              sampleId,
              beats,
              beatIndex: i,
              sequenceLength: pattern.sequence.length
            });
          }
        }
      });
    };

    canvas.onmouseleave = e => {
      hoveredBeatsBySample = hoveredBeatsBySample.map(sample => []);
      markerHoverId = undefined;
      context.clearRect(0, 0, canvas.width, canvas.height);
      renderSong(hoveredBeatsBySample);
    };

    renderSong(hoveredBeatsBySample);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBeat, darkColors, lightColors, sequences, beats, samples]);

  const sampleBorderOffset = samples.length;
  const sampleOffset = 50 * samples.length;
  const markerWidth = 20;
  const markerHeight = 20;

  return (
    <>
      <canvas
        className="song-grid"
        ref={canvasElement}
        height={markerHeight + sampleOffset + sampleBorderOffset}
        width={20 * numberOfBeats}
      ></canvas>
    </>
  );
};

Tracks.propTypes = {
  lightColors: PropTypes.array,
  darkColors: PropTypes.array,
  regionColors: PropTypes.array,
  samples: PropTypes.array,
  numberOfBeats: PropTypes.number,
  currentBeat: PropTypes.number,
  onUpdateSample: PropTypes.func
};

export default Tracks;
