import React, { useState } from "react";
import PropTypes from "prop-types";

const SongEditor = ({
  colors,
  userId,
  jwtToken,
  song,
  onCreateSong,
  onUpdateTitle
}) => {
  let title;
  if (song) title = song.title;

  const [newTitle, setNewTitle] = useState(title || "");

  const handleTitleChange = e => {
    setNewTitle(e.target.value);
  };

  const handleSaveSongFormSubmit = async e => {
    e.preventDefault();

    onCreateSong(newTitle);
  };

  const handleEditTitleFormSubmit = async e => {
    e.preventDefault();

    onUpdateTitle(newTitle);
  };

  return (
    <>
      {!title && (
        <form onSubmit={handleSaveSongFormSubmit}>
          <p>Give your song the proper title it deserves.</p>
          <input
            type="text"
            value={newTitle}
            placeholder="Song Title"
            onChange={handleTitleChange}
          />
          <button style={{ backgroundColor: colors[4] }}>Save Song</button>
        </form>
      )}
      {title && (
        <form onSubmit={handleEditTitleFormSubmit}>
          <p>Change the title of your song.</p>
          <input
            type="text"
            value={newTitle}
            placeholder="song title"
            onChange={handleTitleChange}
          />
          <button style={{ backgroundColor: colors[2] }}>Change Title</button>
        </form>
      )}
    </>
  );
};

SongEditor.propTypes = {
  colors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  song: PropTypes.object,
  onCreateSong: PropTypes.func,
  onUpdateTitle: PropTypes.func
};

export default SongEditor;
