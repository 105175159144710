const sequinsLogo = colors => `
  <svg xmlns="http://www.w3.org/2000/svg" width="100.74" height="100.023" viewBox="0 0 703 698">
    <defs>
      <style>
        .cls-1 { fill: ${colors[0]}; }

        .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
          fill-rule: evenodd;
        }

        .cls-2 { fill: ${colors[1]}; }

        .cls-3 { fill: ${colors[2]}; }

        .cls-4 { fill: ${colors[3]}; }

        .cls-5 { fill: ${colors[4]}; }

        .cls-6 { fill: ${colors[5]}; }

        .cls-7 { fill: ${colors[6]}; }

        .cls-8 { fill: ${colors[7]}; }

        .cls-9 { fill: ${colors[8]}; }
      </style>
    </defs>
    <path id="Polygon_1_copy_3" data-name="Polygon 1 copy 3" class="cls-1" d="M583.691,356.348L517.942,515.925l-158.731,66.1-158.731-66.1L134.732,356.348,200.48,196.771l158.731-66.1,158.731,66.1Zm-161.367-1.52-20.151,48.909L353.523,424l-48.649-20.259-20.151-48.909,20.151-48.908,48.649-20.259,48.65,20.259Z"/>
    <path id="Rectangle_1_copy" data-name="Rectangle 1 copy" class="cls-2" d="M354,565L198,500l-93.36,96.875,248.294,102.29Z"/>
    <path class="cls-3" d="M351,564l156-65,93.36,96.875L352.066,698.165Z"/>
    <path id="Rectangle_1_copy_6" data-name="Rectangle 1 copy 6" class="cls-4" d="M502.512,501.715l64.347-156.27,134.516,2.485-103.24,247.9Z"/>
    <path id="Rectangle_1_copy_7" data-name="Rectangle 1 copy 7" class="cls-5" d="M200.488,505.715l-64.347-156.27L1.625,351.93l103.24,247.9Z"/>
    <path id="Rectangle_1_copy_4" data-name="Rectangle 1 copy 4" class="cls-6" d="M200.488,198.285l-64.347,156.27L1.625,352.07l103.24-247.9Z"/>
    <path id="Rectangle_1_copy_3" data-name="Rectangle 1 copy 3" class="cls-7" d="M354,137L198,202l-93.36-96.875L352.934,2.835Z"/>
    <path id="Rectangle_1_copy_2" data-name="Rectangle 1 copy 2" class="cls-8" d="M351,137l156,65,93.36-96.875L352.066,2.835Z"/>
    <path id="Rectangle_1_copy_5" data-name="Rectangle 1 copy 5" class="cls-9" d="M502.512,198.285l64.347,156.27,134.516-2.485-103.24-247.9Z"/>
  </svg>
`;

export default sequinsLogo;
