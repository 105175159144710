import React, { useState } from "react";
import PropTypes from "prop-types";

import { signup } from "../services/users-service";

const Signup = ({ colors, userId, jwtToken, onLogin, onFinishSignup }) => {
  const [email, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailInputChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handlePasswordInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();

    const { user, token } = await signup(email, password);

    onLogin(user, token);

    onFinishSignup();
  };

  return (
    <>
      {!userId && !jwtToken && (
        <form onSubmit={handleSignupFormSubmit}>
          <p>Sign up to save your song.</p>
          <input
            type="text"
            value={email}
            placeholder="email address"
            onChange={handleEmailInputChange}
          />
          <input
            type="password"
            value={password}
            placeholder="password"
            onChange={handlePasswordInputChange}
          />
          <button style={{ backgroundColor: colors[8] }}>Create account</button>
        </form>
      )}
    </>
  );
};

Signup.propTypes = {
  colors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  onLogin: PropTypes.func,
  onFinishSignup: PropTypes.func,
};

export default Signup;
