import React from "react";
import PropTypes from "prop-types";

import {
  IoIosMusicalNote,
  IoIosMusicalNotes,
  IoIosSave,
  IoIosKey,
  IoMdAdd,
} from "react-icons/io";
import { MdKeyboardArrowDown, MdEdit } from "react-icons/md";
import { FiShare } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import sequinsLogo from "../common/sequins-logo";

const NavigationBar = ({
  logoColors,
  userId,
  jwtToken,
  song,
  onShowSignup,
  onShowLogin,
  onShowSongEditor,
  onShowShare,
  onShowSongSaved,
  onShowFileUploader,
}) => {
  const handleSaveSongButtonClick = (e) => {
    if (!userId && !jwtToken) {
      return onShowSignup(e);
    } else {
      return onShowSongSaved();
    }
  };

  return (
    <div className="navigation-bar">
      <div className="navigation-buttons justify-left">
        <a className="logo-link" href="/">
          <img
            className="logo"
            src={`data:image/svg+xml;utf8,${sequinsLogo(logoColors)}`}
          />
          Sequins
        </a>
      </div>
      <div className="navigation-buttons">
        {userId && (
          <button
            className="navigation-button add-track"
            onClick={onShowFileUploader}
          >
            <IoIosMusicalNote className="navigation-bar-icon" />
            Upload Sample
          </button>
        )}
      </div>
      <nav className="navigation-buttons justify-right" role="navigation">
        <ul>
          {song && song.title && userId && jwtToken && (
            <li>
              <button
                className="navigation-button-dropdown song-title"
                aria-haspopup={true}
              >
                <IoIosMusicalNotes className="navigation-bar-icon" />
                {song.title}
                <MdKeyboardArrowDown className="navigation-bar-icon navigation-bar-down-arrow" />
              </button>
              <ul className="navigation-dropdown" aria-label="submenu">
                <li>
                  <button
                    className="navigation-button-subitem"
                    onClick={onShowSongEditor}
                  >
                    <MdEdit className="navigation-bar-icon-small" />
                    Change title
                  </button>
                </li>
                <li>
                  <button
                    className="navigation-button-subitem"
                    onClick={onShowShare}
                  >
                    <FiShare className="navigation-bar-icon-small" />
                    Share song
                  </button>
                </li>
                <li>
                  <button className="navigation-button-subitem">
                    <IoMdAdd className="navigation-bar-icon-small" />
                    New song
                  </button>
                </li>
                <li>
                  <button className="navigation-button-subitem">
                    <AiFillDelete className="navigation-bar-icon-small" />
                    Delete song
                  </button>
                </li>
              </ul>
            </li>
          )}
          <li>
            <button
              className="navigation-button save-button"
              onClick={handleSaveSongButtonClick}
            >
              <IoIosSave className="navigation-bar-icon" />
              Save
            </button>
          </li>
          <li>
            {!userId && (
              <button
                className="navigation-button login-button"
                onClick={onShowLogin}
              >
                <IoIosKey className="navigation-bar-icon" />
                Login
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavigationBar.propTypes = {
  logoColors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  song: PropTypes.object,
  onShowSignup: PropTypes.func,
  onShowLogin: PropTypes.func,
  onShowShare: PropTypes.func,
  onShowSongEditor: PropTypes.func,
  onShowSongSaved: PropTypes.func,
  onShowFileUploader: PropTypes.func,
};

export default NavigationBar;
