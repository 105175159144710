import { options } from "./fetch";

export const login = async (email, password) => {
  const data = { email, password };

  const fetchUrl = `${process.env.API_BASE_URL}/api/login`;
  const fetchParams = {
    ...options("POST"),
    body: JSON.stringify(data),
  };

  const res = await fetch(fetchUrl, fetchParams)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) console.error(res.error);
      return res;
    });

  console.log("RES: ", res);
  const { user, token } = res;
  let id;
  if (user) id = user.id;

  return { id, token };
};

export const currentUser = async (jwtToken) => {
  const fetchUrl = `${process.env.API_BASE_URL}/api/current-user`;
  const fetchParams = {
    ...options("GET", jwtToken),
  };

  try {
    const res = await fetch(fetchUrl, fetchParams);
    const json = await res.json();
    const { user, token } = json;

    return { user, token };
  } catch (error) {
    console.log("error fetching current user: ", error);
  }
};

const authService = { login, currentUser };

export default authService;
