import React from "react";
import PropTypes from "prop-types";

import qwertyValues from "../utils/qwerty-values";

import { REST, SEQUENCE } from "../common/index";

const StepButton = ({
  id,
  color,
  step,
  isSelected,
  isCurrent,
  onClickStepButton,
  onRightClickStepButton,
  onKeyUp
}) => {
  const text = () => {
    let text = id + 1;
    if (isSelected) {
      return "?";
    } else if (step == REST) {
      return text;
    } else {
      const charCode = qwertyValues[step];
      return String.fromCharCode(charCode);
    }
  };

  const className = () => {
    let name = "step-button";

    if (isCurrent) {
      name += " step-playing";
    }

    if (color) {
      name += " step-assigned";
    }

    return name;
  };

  return (
    <button
      style={{ backgroundColor: color, borderColor: color }}
      className={className()}
      onClick={onClickStepButton}
      onContextMenu={onRightClickStepButton}
      onKeyPress={e => onKeyUp(e, id)}
    >
      {text()}
    </button>
  );
};

StepButton.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelected: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClickStepButton: PropTypes.func,
  onRightClickStepButton: PropTypes.func,
  onKeyUp: PropTypes.func
};

export default StepButton;
