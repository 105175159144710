import _ from "lodash";

import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import AudioContextContext from "../contexts/audio-context-context";

import WaveSurfer from "wavesurfer.js";
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";

import Audio from "./audio-wrapper";

const Waveform = ({
  id,
  songId,
  audio,
  filepath,
  regions,
  onUpdateSample,
  onUpdateDuration
}) => {
  const audioContext = useContext(AudioContextContext);

  useEffect(() => {
    if (_.isEmpty(audio)) {
      const wavesurfer = WaveSurfer.create({
        audioContext: audioContext,
        container: `#waveform-${id}`,
        responsive: true,
        backend: "MediaElementWebAudio",
        barWidth: 1,
        barHeight: 0.75,
        height: 69,
        waveColor: "#ababab",
        progressColor: "#fff",
        cursorColor: "#fff",
        interact: false,
        plugins: [Regions.create({ regions })]
      });

      if (filepath[0] === "/") {
        wavesurfer.load(filepath);
      } else {
        const audio = document.createElement("audio");
        audio.src = filepath;
        audio.crossOrigin = "anonymous";
        wavesurfer.load(audio);
      }

      const newAudio = new Audio(wavesurfer);
      newAudio.on("ready", () => {
        onUpdateDuration(newAudio.duration());
      });

      onUpdateSample({ id, audio: newAudio });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songId, onUpdateDuration]);

  return <div id={`waveform-${id}`} className="sample"></div>;
};

Waveform.propTypes = {
  id: PropTypes.number,
  songId: PropTypes.number,
  audio: PropTypes.object,
  filepath: PropTypes.string,
  regions: PropTypes.array,
  onUpdateSample: PropTypes.func,
  onUpdateDuration: PropTypes.func
};

export default Waveform;
