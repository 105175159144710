export const STOPPED = "STOPPED";
export const PLAYING_SONG = "PLAYING_SONG";
export const PLAYING_SAMPLE = "PLAYING_SAMPLE";
export const PLAYING_REGION = "PLAYING_REGION";
export const PLAYING_SEQUENCE = "PLAYING_SEQUENCE";
export const PAUSED = "PAUSED";
export const REST = "REST";
export const R = REST;
export const SEQUENCE = "SEQUENCE";
export const S = SEQUENCE;
export const SEQUENCE_START = "SEQUENCE_START";
export const SS = SEQUENCE_START;
export const SEQUENCE_END = "SEQUENCE_END";
export const SE = SEQUENCE_END;
export const SEQUENCE_PREVIEW = "SEQUENCE_PREVIEW";
export const SP = SEQUENCE_PREVIEW;
