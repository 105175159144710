import _ from "lodash";

const songsByDateAsc = songs => {
  songs.sort((a, b) => {
    const keyA = new Date(a.updatedAt);
    const keyB = new Date(b.updatedAt);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  return songs;
};

export const latestSong = songs => {
  const songsSorted = songsByDateAsc(songs);

  return songsSorted[songsSorted.length - 1];
};

export const stateForDatabase = state => {
  const newState = _.cloneDeep(state);
  newState.samples.map(sample => delete sample.audio);

  return newState;
};

const slugify = string => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const generateSlug = (existingSlug, toBeSlugged) => {
  if (existingSlug) return existingSlug;

  return slugify(toBeSlugged);
};

export const generateShareUrl = (userId, slug) => {
  return `${process.env.BASE_URL_SHORT}/users/${userId}/share/${slug}`;
};
