import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import PatternButton from "./pattern-button";

import { IoMdAdd } from "react-icons/io";

import { getFilename } from "../utils/file-utils";

const Patterns = ({
  regionColors,
  sampleId,
  filepath,
  patterns,
  selectedPatternId,
  numberOfChops,
  playbackStatus,
  onUpdateSample,
  onCreatePattern,
  onDeletePattern,
  onStop
}) => {
  const renderPatternButtons = () => {
    return patterns.map((pattern, i) => {
      return (
        <PatternButton
          key={i}
          regionColors={regionColors}
          id={i}
          sampleId={sampleId}
          selectedPatternId={selectedPatternId}
          sequence={pattern.sequence}
          numberOfChops={numberOfChops}
          playbackStatus={playbackStatus}
          onUpdateSample={onUpdateSample}
          onDeletePattern={e => onDeletePattern(e, i)}
          onStop={onStop}
        />
      );
    });
  };

  return (
    <div className="pattern-controls">
      <div className="sample-filename">{getFilename(filepath)}</div>
      {renderPatternButtons()}
      <button className="pattern-tab-add" onClick={onCreatePattern}>
        <IoMdAdd className="pattern-tab-icon" />
      </button>
    </div>
  );
};

Patterns.propTypes = {
  regionColors: PropTypes.array,
  sampleId: PropTypes.number,
  filepath: PropTypes.string,
  patterns: PropTypes.array,
  selectedPatternId: PropTypes.number,
  numberOfChops: PropTypes.number,
  playbackStatus: PropTypes.string,
  onUpdateSample: PropTypes.func,
  onCreatePattern: PropTypes.func,
  onDeletePattern: PropTypes.func,
  onStop: PropTypes.func
};

export default Patterns;
