import React from "react";
import PropTypes from "prop-types";

const Confirmation = ({ colors, confirmation, onCloseTray }) => {
  return (
    <div>
      <p>{confirmation}</p>
      <button style={{ backgroundColor: colors[1] }} onClick={onCloseTray}>
        OK, Thanks!
      </button>
    </div>
  );
};

Confirmation.propTypes = {
  colors: PropTypes.array,
  confirmation: PropTypes.string,
  onCloseTray: PropTypes.func
};

export default Confirmation;

export const SONG_SAVED = "Song saved successfully!";
export const SONG_TITLE_UPDATED = "Song titled updated!";
export const SAMPLE_ADDED = filename => `${filename} added successfully!`;
