const qwertyValues = [
  49, // 1
  50, // 2
  51, // 3
  52, // 4
  53, // 5
  54, // 6
  55, // 7
  56, // 8
  57, // 9
  48, // 0
  113, // q
  119, // w
  101, // e
  114, // r
  116, // t
  121, // y
  117, // u
  105, // i
  111, // o
  112, // p
  97, // a
  115, // s
  100, // d
  102, // f
  103, // g
  104, // h
  106, // j
  107, // k
  108, // l
  122, // z
  120, // x
  99, // c
  118, // v
  98, // b
  110, // n
  109, // m
  33, // !
  64, // @
  35, // #
  36, // $
  37, // %
  94, //^
  38, // &
  42, // *,
  40, // (
  41, // )
  81, // Q
  87, // W
  69, // E
  82, // R
  84, // T
  89, // Y
  85, // U
  73, // I
  79, // O
  80, // P
  65, // A
  83, // S
  68, // D
  70, // F
  71, // G
  72, // H
  74, // J
  75, // K
  76, // L
  90, // Z
  88, // X
  67, // C
  86, // V
  66, // B
  78, // N
  77 // M
];

export default qwertyValues;
