import { SEQUENCE_START, SEQUENCE_END, REST } from "../common";

export const findAllIndices = (arr, val) => {
  const indices = [];
  for (let i = 0; i < arr.length; i++) if (arr[i].type === val) indices.push(i);
  return indices;
};

export const findSequenceStartIndex = (beats, beatIndex) => {
  let currentIndex = beatIndex;

  if (beats[currentIndex].type === SEQUENCE_START) {
    return currentIndex;
  }
  return findSequenceStartIndex(beats, currentIndex - 1);
};

export const findSequenceEndIndex = (beats, beatIndex) => {
  let currentIndex = beatIndex;

  if (beats[currentIndex].type === SEQUENCE_END) {
    return currentIndex;
  }

  return findSequenceEndIndex(beats, currentIndex + 1);
};

export const updateSequenceLength = ({ sequence, numberOfSteps }) => {
  const sequenceLength = sequence.length;
  const newSequence = [...sequence];

  if (sequenceLength === numberOfSteps) return sequence;

  if (sequenceLength > numberOfSteps) {
    newSequence.length = numberOfSteps;
  } else if (sequenceLength < numberOfSteps) {
    newSequence.length = numberOfSteps;
    newSequence.fill(REST, sequenceLength);
  }

  return newSequence;
};
