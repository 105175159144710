import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";

import Daw from "./daw";

import songsService from "../services/songs-service";

import { latestSong, stateForDatabase } from "../utils/song-utils";
import { restBeat } from "../utils/beat-utils";

import { R, UNTITILED_SONG } from "../common/index";

import {
  loadSong,
  updateId,
  updateTitle,
  updateSongState,
  addSample,
  updateSample,
  deleteSample,
  updateSlug,
  updatePattern,
  deletePattern,
  updateBeats,
  LOAD_SONG,
  UPDATE_ID,
  UPDATE_TITLE,
  UPDATE_SONG_STATE,
  ADD_SAMPLE,
  UPDATE_SAMPLE,
  DELETE_SAMPLE,
  UPDATE_SLUG,
  UPDATE_PATTERN,
  DELETE_PATTERN,
  UPDATE_BEATS,
} from "../actions/song-actions";

const Song = ({ logoColors, user, jwtToken, onLogin }) => {
  let userId;
  if (user) userId = user.id;

  let defaultControlValues = {
    volume: 70,
    numberOfChops: 26,
    numberOfSteps: 12,
    playbackSpeed: 0,
    filter: 0,
    pan: 0,
  };

  let defaultSampleValues = {
    audio: undefined,
    patterns: [
      {
        sequence: [1, 4, R, 1, 4, R, 7, R, R, 7, R, R],
        ...defaultControlValues,
      },
    ],
    selectedPatternId: 0,
    beats: new Array(400).fill(restBeat),
    currentSequenceStep: 0,
  };

  const songReducer = (state, action) => {
    switch (action.type) {
      case LOAD_SONG:
        return loadSong(state, action.songFromDatabase);
      case UPDATE_ID:
        return updateId(state, action.id);
      case UPDATE_TITLE:
        return updateTitle(state, action.title);
      case UPDATE_SONG_STATE:
        return updateSongState(state, action.songStateUpdate);
      case ADD_SAMPLE:
        return addSample(state, action.sample, defaultSampleValues);
      case UPDATE_SAMPLE:
        return updateSample(state, action.sampleUpdate);
      case DELETE_SAMPLE:
        return deleteSample(state, action.id);
      case UPDATE_SLUG:
        return updateSlug(state, action.slug);
      case UPDATE_PATTERN:
        return updatePattern(state, action.patternUpdate);
      case DELETE_PATTERN:
        return deletePattern(state, action.patternDelete);
      case UPDATE_BEATS:
        return updateBeats(state, action.beatsUpdate);
      default:
        throw new Error(`missing action ${action.type} for songReducer`);
    }
  };

  const defaultSong = {
    id: undefined,
    title: "",
    state: {
      tempo: 120,
      numberOfBeats: 400,
      samples: [
        {
          id: 0,
          filepath:
            "https://sequins-development.s3.amazonaws.com/default-samples/tuba.wav",
          ...defaultSampleValues,
        },
        {
          id: 1,
          filepath:
            "https://sequins-development.s3.amazonaws.com/default-samples/wind-chimes.wav",
          ...defaultSampleValues,
        },
        {
          id: 2,
          filepath:
            "https://sequins-development.s3.amazonaws.com/default-samples/u-and-me-and-co.mp3",
          ...defaultSampleValues,
        },
        {
          id: 3,
          filepath:
            "https://sequins-development.s3.amazonaws.com/default-samples/cash-register.wav",
          ...defaultSampleValues,
        },
      ],
    },
  };

  const existingSongOrDefault = () => {
    if (user && user.songs) {
      return latestSong(user.songs);
    } else {
      return defaultSong;
    }
  };
  const [song, songDispatch] = useReducer(songReducer, existingSongOrDefault());

  const dispatchLoadSong = (songFromDatabase) => {
    songDispatch({ type: LOAD_SONG, songFromDatabase });
  };

  const dispatchUpdateId = (id) => {
    songDispatch({ type: UPDATE_ID, id });
  };

  const dispatchUpdateTitle = (title) => {
    songDispatch({ type: UPDATE_TITLE, title });
  };

  const dispatchUpdateSongState = (songStateUpdate) => {
    songDispatch({ type: UPDATE_SONG_STATE, songStateUpdate });
  };

  const dispatchAddSample = (sample) => {
    songDispatch({ type: ADD_SAMPLE, sample });
  };

  const dispatchUpdateSample = (sampleUpdate) => {
    songDispatch({ type: UPDATE_SAMPLE, sampleUpdate });
  };

  const dispatchDeleteSample = (id) => {
    songDispatch({ type: DELETE_SAMPLE, id });
  };

  const dispatchUpdateSlug = (slug) => {
    songDispatch({ type: UPDATE_SLUG, slug });
  };

  const dispatchUpdatePattern = (patternUpdate) => {
    songDispatch({ type: UPDATE_PATTERN, patternUpdate });
  };

  const dispatchDeletePattern = (patternDelete) => {
    songDispatch({ type: DELETE_PATTERN, patternDelete });
  };

  const dispatchUpdateBeats = (beatsUpdate) => {
    songDispatch({ type: UPDATE_BEATS, beatsUpdate });
  };

  const handleUpdateSlug = async (slug) => {
    const { id } = song;
    await songsService.updateSong(jwtToken, userId, id, { slug });
    dispatchUpdateSlug(slug);
  };

  const handleCreateSong = async (title) => {
    dispatchUpdateTitle(title);

    const { id } = await songsService.createSong(
      jwtToken,
      userId,
      title,
      stateForDatabase(song.state)
    );
    dispatchUpdateId(id);
  };

  const handleSaveSong = async () => {
    const { id, title, state } = song;

    await songsService.updateSong(jwtToken, userId, id, {
      title,
      state: stateForDatabase(state),
    });
  };

  const handleLogin = (user, token) => {
    onLogin(user, token);
    if (user.songs) {
      song.state.samples.map((sample) => sample.audio.destroy());
      dispatchLoadSong(latestSong(user.songs));
    }
  };
  return (
    <>
      <Daw
        logoColors={logoColors}
        userId={userId}
        jwtToken={jwtToken}
        onLogin={handleLogin}
        song={song}
        onCreateSong={handleCreateSong}
        onUpdateTitle={dispatchUpdateTitle}
        onUpdateSongState={dispatchUpdateSongState}
        onSaveSong={handleSaveSong}
        onAddSample={dispatchAddSample}
        onUpdateSample={dispatchUpdateSample}
        onDeleteSample={dispatchDeleteSample}
        onUpdateSlug={handleUpdateSlug}
        onUpdatePattern={dispatchUpdatePattern}
        onDeletePattern={dispatchDeletePattern}
        onUpdateBeats={dispatchUpdateBeats}
      />
    </>
  );
};

Song.propTypes = {
  logoColors: PropTypes.array,
  user: PropTypes.object,
  jwtToken: PropTypes.string,
  onLogin: PropTypes.func,
};

export default Song;
