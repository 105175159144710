const buildColors = (alpha, numberOfColors, light, multiplier) => {
  let hue = 7;
  let sat = 30;
  let colors = [];
  for (let x = 0; x < numberOfColors; x++) {
    colors.push(
      `hsla(${(hue + x) * multiplier}, ${sat}%, ${light}%, ${alpha})`
    );
  }
  return colors;
};

export default buildColors;
