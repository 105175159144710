import React from "react";
import PropTypes from "prop-types";

import Sample from "./sample";

const Samples = ({
  darkColors,
  regionColorsTransparent,
  regionColorsSolid,
  songId,
  samples,
  selectedSampleId,
  msPerStep,
  songPlaybackStatus,
  onClickSample,
  onUpdateSample,
  onDeleteSample,
  onUpdatePattern,
  onDeletePattern,
  onUpdateSelectedSampleId,
  onUpdateBeats
}) => {
  const renderSamples = () => {
    return samples.map((sample, i) => {
      const handleOnUpdateSelectedSampleId = ei => {
        onUpdateSelectedSampleId(i);
      };

      return (
        <Sample
          id={sample.id}
          key={sample.id}
          darkColor={darkColors[i]}
          regionColorsTransparent={regionColorsTransparent}
          regionColorsSolid={regionColorsSolid}
          songId={songId}
          sample={sample}
          isSelectedSample={selectedSampleId === sample.id}
          filepath={sample.filepath}
          msPerStep={msPerStep}
          songPlaybackStatus={songPlaybackStatus}
          onClickSample={onClickSample}
          onUpdateSample={onUpdateSample}
          onDeleteSample={onDeleteSample}
          onUpdatePattern={onUpdatePattern}
          onDeletePattern={onDeletePattern}
          onUpdateSelectedSampleId={handleOnUpdateSelectedSampleId}
          onUpdateBeats={onUpdateBeats}
        />
      );
    });
  };

  return <div className="samples-container">{renderSamples()}</div>;
};

Samples.propTypes = {
  darkColors: PropTypes.array,
  regionColorsTransparent: PropTypes.array,
  regionColorsSolid: PropTypes.array,
  songId: PropTypes.number,
  samples: PropTypes.array,
  selectedSampleId: PropTypes.number,
  msPerStep: PropTypes.number,
  songPlaybackStatus: PropTypes.string,
  onClickSample: PropTypes.func,
  onUpdateSample: PropTypes.func,
  onDeleteSample: PropTypes.func,
  onUpdatePattern: PropTypes.func,
  onDeletePattern: PropTypes.func,
  onUpdateSelectedSampleId: PropTypes.func,
  onUpdateBeats: PropTypes.func
};

export default Samples;
