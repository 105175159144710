import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import SampleTransport from "./sample-transport";
import Patterns from "./patterns";
import Waveform from "./waveform";
import Sequencer from "./sequencer";
import Controls from "./controls";

import buildColors from "../utils/build-colors";
import { updateSequences } from "../utils/beat-utils";
import { updateSequenceLength } from "../utils/sequence-utils";
import qwertyValues from "../utils/qwerty-values";
import {
  STOPPED,
  PLAYING_SAMPLE,
  PLAYING_REGION,
  PLAYING_SEQUENCE,
  PAUSED
} from "../common/index";
import { REST, R, SEQUENCE } from "../common/index";

const Sample = ({
  id,
  darkColor,
  regionColorsTransparent,
  regionColorsSolid,
  songId,
  sample,
  isSelectedSample,
  filepath,
  msPerStep,
  songPlaybackStatus,
  onClickSample,
  onUpdateSample,
  onDeleteSample,
  onUpdatePattern,
  onDeletePattern,
  onUpdateSelectedSampleId,
  onUpdateBeats
}) => {
  const [regions, setRegions] = useState([]);

  const [playbackStatus, setPlaybackStatus] = useState(STOPPED);
  const [looping, setLooping] = useState(false);
  const [paused, setPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedStep, setSelectedStep] = useState(undefined);
  const [currentRegion, setCurrentRegion] = useState(null);

  const { audio, beats, patterns, selectedPatternId } = sample;
  const pattern = patterns[selectedPatternId];
  const {
    sequence,
    volume,
    numberOfChops,
    numberOfSteps,
    playbackSpeed,
    filter,
    pan
  } = pattern;

  const playSample = (offset = 0) => {
    if (playbackStatus === PLAYING_SAMPLE) return;

    audio.on("finish", () => {
      if (looping) {
        return playSample();
      }

      setCurrentTime(0);
      audio.seekTo(0);
      setPlaybackStatus(STOPPED);
    });

    audio.play(currentTime);
    setPlaybackStatus(PLAYING_SAMPLE);
  };

  const playRegion = region => {
    return new Promise(resolve => {
      region.play();
      setTimeout(resolve, msPerStep);
    });
  };

  const playRest = () => {
    return new Promise(resolve => {
      setTimeout(resolve, msPerStep);
    });
  };

  const isFinalStep = () => {
    return currentStep === sequence.length - 1;
  };

  const numberOfStepsWasReduced = () => {
    return currentStep >= sequence.length;
  };

  const playSequence = async () => {
    setPlaybackStatus(PLAYING_SEQUENCE);
    if (numberOfStepsWasReduced()) {
      return;
    }

    let region = REST;
    if (sequence[currentStep] != REST) {
      region = audio.getRegionByIndex(sequence[currentStep]);
    }

    if (region === REST) {
      await playRest();
    } else {
      await playRegion(region);
    }

    if (isFinalStep() && looping) {
      setCurrentStep(0);
    } else if (isFinalStep() && !looping) {
      setPlaybackStatus(STOPPED);
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const pause = () => {
    if (
      playbackStatus === PLAYING_SAMPLE ||
      playbackStatus === PLAYING_REGION ||
      playbackStatus === PLAYING_SEQUENCE
    ) {
      setCurrentTime(audio.getCurrentTime());
      audio.pause();
      setPlaybackStatus(PAUSED);
    }
  };

  const stop = () => {
    audio.stop();
    setPlaybackStatus(STOPPED);
  };

  const loop = () => {
    setLooping(!looping);
  };

  const handleUpdateVolume = value => {
    audio.setVolume(value);
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        volume: value
      }
    });
  };

  const updateTempo = value => {
    setTempo(value);
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        tempo: value
      }
    });
  };

  const handleUpdateNumberOfChops = value => {
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        numberOfChops: value
      }
    });
  };

  const handleUpdateNumberOfSteps = numberOfSteps => {
    const newSequence = updateSequenceLength({ sequence, numberOfSteps });

    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: { numberOfSteps, sequence: newSequence }
    });

    const newBeats = updateSequences({
      patternId: selectedPatternId,
      beats,
      sequence: newSequence
    });

    onUpdateBeats({ sampleId: id, newBeats });
  };

  const handleUpdatePlaybackSpeed = value => {
    audio.setPlaybackSpeed(value);
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        playbackSpeed: value
      }
    });
  };

  const handleUpdateFilter = value => {
    audio.setFilter(value);
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        filter: value
      }
    });
  };

  const handleUpdatePan = value => {
    audio.setPan(value);
    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: {
        pan: value
      }
    });
  };

  const renderClickBlocker = () => {
    if (!isSelectedSample) {
      return <div className="click-blocker"></div>;
    }
  };

  const handleCreatePattern = e => {
    const newSequence = new Array(8).fill(REST);
    let defaultControlValues = {
      volume: 70,
      numberOfChops: 26,
      numberOfSteps: 12,
      playbackSpeed: 0,
      filter: 0,
      pan: 0
    };
    const newPattern = {
      sequence: newSequence,
      ...defaultControlValues
    };
    const newPatterns = [...patterns, newPattern];
    onUpdateSample({ id: sample.id, patterns: newPatterns });
  };

  const handleDeletePattern = (e, id) => {
    e.preventDefault();

    if (id === 0) return;

    if (selectedPatternId === id) {
      onUpdateSample({ id: sample.id, selectedPatternId: id - 1 });
    }

    onDeletePattern({ sampleId: sample.id, patternId: id });

    return false;
  };

  useEffect(() => {
    const handleSelectedStepClick = i => {
      const newSequence = [...sequence];
      newSequence[selectedStep] = i;
      setSelectedStep(undefined);
      onUpdatePattern({
        sampleId: id,
        patternId: selectedPatternId,
        update: { sequence: newSequence }
      });
      const newBeats = updateSequences({
        patternId: selectedPatternId,
        beats,
        sequence: newSequence
      });

      onUpdateBeats({ sampleId: id, newBeats });
    };

    const buildRegions = () => {
      const regions = [];
      const regionLength = duration / numberOfChops;
      const colors = regionColorsSolid;

      for (let i = 0; i < numberOfChops; i++) {
        regions.push({
          start: regionLength * i,
          end: regionLength * (i + 1),
          color: buildColors(0.5, numberOfChops, 50, 30)[i],
          loop: false,
          drag: false,
          resize: false,
          attributes: {
            label: String.fromCharCode(qwertyValues[i])
          }
        });
      }

      return regions;
    };

    const newRegions = buildRegions();
    setRegions(newRegions);
    if (!_.isEmpty(audio)) {
      audio.clearRegions();
      newRegions.forEach(region => audio.addRegion(region));
      audio.regionKeys.map((regionKey, i) => {
        const region = audio.getRegionByIndex(i);
        region.on("click", () => {
          if (selectedStep >= 0) {
            handleSelectedStepClick(i);
          }
          playRegion(region);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, numberOfChops, selectedPatternId, sequence, filepath, audio]);

  useEffect(() => {
    if (audio) {
      audio.setFilter(pattern.filter);
      audio.setPan(pattern.pan);
      audio.setPlaybackSpeed(pattern.playbackSpeed);
    }
  }, [audio, pattern, selectedPatternId]);

  useEffect(() => {
    const playPressedRegion = e => {
      if (!isSelectedSample) return;
      const charCode = e.which;
      const usedQwertyValues = qwertyValues.slice(0, regions.length);
      if (!usedQwertyValues.includes(e.which)) return;
      const regionId = qwertyValues.indexOf(charCode);
      playRegion(audio.getRegionByIndex(regionId));
    };

    document.addEventListener("keypress", playPressedRegion);

    return () => {
      document.removeEventListener("keypress", playPressedRegion);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, regions, msPerStep, isSelectedSample]);

  useEffect(() => {
    if (playbackStatus === PLAYING_SEQUENCE) {
      playSequence();
    } else if (playbackStatus === STOPPED && songPlaybackStatus != STOPPED) {
      setCurrentStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackStatus, currentStep]);

  return (
    <div
      key={id}
      className="sample-container"
      id={isSelectedSample ? "selected-sample" : ""}
      style={{ backgroundColor: darkColor }}
      onMouseDown={() => onClickSample(id)}
    >
      {renderClickBlocker()}
      <SampleTransport
        id={id}
        playbackStatus={playbackStatus}
        looping={looping}
        onPlaySample={playSample}
        onPlaySequence={playSequence}
        onPause={pause}
        onStop={stop}
        onLoop={loop}
        onDeleteSample={onDeleteSample}
      />
      <div className="right-container">
        <Patterns
          regionColors={regionColorsSolid}
          sampleId={sample.id}
          filepath={filepath}
          patterns={patterns}
          selectedPatternId={selectedPatternId}
          numberOfChops={numberOfChops}
          playbackStatus={playbackStatus}
          onUpdateSample={onUpdateSample}
          onCreatePattern={handleCreatePattern}
          onDeletePattern={handleDeletePattern}
          onStop={stop}
        />
        <Waveform
          id={id}
          songId={songId}
          audio={audio}
          filepath={filepath}
          regions={regions}
          onUpdateSample={onUpdateSample}
          onUpdateDuration={setDuration}
        />
        <Sequencer
          id={id}
          colors={regionColorsTransparent}
          sample={sample}
          regions={regions}
          numberOfSteps={numberOfSteps}
          selectedStep={selectedStep}
          playbackStatus={playbackStatus}
          songPlaybackStatus={songPlaybackStatus}
          currentStep={currentStep}
          currentSequenceStep={sample.currentSequenceStep}
          onUpdateSample={onUpdateSample}
          onUpdateSelectedStep={setSelectedStep}
          onUpdatePattern={onUpdatePattern}
          onUpdateBeats={onUpdateBeats}
        />
        <Controls
          volume={volume}
          numberOfChops={numberOfChops}
          numberOfSteps={numberOfSteps}
          playbackSpeed={playbackSpeed}
          filter={filter}
          pan={pan}
          onUpdateVolume={handleUpdateVolume}
          onUpdateNumberOfChops={handleUpdateNumberOfChops}
          onUpdateNumberOfSteps={handleUpdateNumberOfSteps}
          onUpdatePlaybackSpeed={handleUpdatePlaybackSpeed}
          onUpdateFilter={handleUpdateFilter}
          onUpdatePan={handleUpdatePan}
          onUpdateSelectedSampleId={onUpdateSelectedSampleId}
        />
      </div>
    </div>
  );
};

Sample.propTypes = {
  id: PropTypes.number,
  darkColor: PropTypes.string,
  regionColorsTransparent: PropTypes.array,
  regionColorsSolid: PropTypes.array,
  songId: PropTypes.number,
  sample: PropTypes.object,
  isSelectedSample: PropTypes.bool,
  filepath: PropTypes.string,
  msPerStep: PropTypes.number,
  songPlaybackStatus: PropTypes.string,
  onClickSample: PropTypes.func,
  onUpdateSample: PropTypes.func,
  onDeleteSample: PropTypes.func,
  onUpdatePattern: PropTypes.func,
  onDeletePattern: PropTypes.func,
  onUpdateSelectedSampleId: PropTypes.func,
  onUpdateBeats: PropTypes.func
};

export default Sample;
