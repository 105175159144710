export const options = (httpMethod, jwtToken, isMultipart = false) => {
  const contentType = isMultipart ? {} : { "Content-Type": "application/json" };
  const auth = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
  const credentials = jwtToken ? { credentials: "include" } : {};

  return {
    headers: {
      ...contentType,
      ...auth,
    },
    method: httpMethod,
    mode: "cors",
    ...credentials,
  };
};
