import React, { useState } from "react";
import PropTypes from "prop-types";

import StepButton from "./step-button";

import { updateSequences } from "../utils/beat-utils";
import { updateSequenceLength } from "../utils/sequence-utils";

import {
  REST,
  R,
  SEQUENCE,
  PLAYING_SEQUENCE,
  PLAYING_SONG
} from "../common/index";
import qwertyValues from "../utils/qwerty-values";

const Sequencer = ({
  id,
  colors,
  sample,
  regions,
  numberOfSteps,
  selectedStep,
  playbackStatus,
  songPlaybackStatus,
  currentStep,
  currentSequenceStep,
  onUpdateSelectedStep,
  onUpdatePattern,
  onUpdateBeats
}) => {
  const { patterns, selectedPatternId, beats } = sample;
  const sequence = patterns[selectedPatternId].sequence;

  const clearStep = i => {
    const newSequence = [...sequence];
    newSequence[i] = REST;
    onUpdateSelectedStep(i);

    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: { sequence: newSequence }
    });

    const newBeats = updateSequences({
      patternId: selectedPatternId,
      beats,
      sequence: newSequence
    });

    onUpdateBeats({ sampleId: id, newBeats });
  };

  const handleStepButtonClick = i => {
    clearStep(i);
  };

  const handleStepButtonRightClick = (e, i) => {
    e.preventDefault();

    clearStep(i);
    return false;
  };

  const updateStepButton = ({ stepId, regionId }) => {
    const newSequence = [...sequence];
    newSequence[stepId] = regionId;
    onUpdateSelectedStep(undefined);

    onUpdatePattern({
      sampleId: id,
      patternId: selectedPatternId,
      update: { sequence: newSequence }
    });

    const newBeats = updateSequences({
      patternId: selectedPatternId,
      beats,
      sequence: newSequence
    });

    onUpdateBeats({ sampleId: id, newBeats });
  };

  const handleKeyUp = (e, i) => {
    const usedQwertyValues = qwertyValues.slice(0, regions.length);
    const charCode = e.which;

    if (!usedQwertyValues.includes(e.which)) return;
    const regionId = qwertyValues.indexOf(e.which);

    updateStepButton({ stepId: i, regionId });
    onUpdateSelectedStep(null);
  };

  const isCurrentStep = i => {
    return (
      (currentStep === i && playbackStatus === PLAYING_SEQUENCE) ||
      (currentSequenceStep === i && songPlaybackStatus === PLAYING_SONG)
    );
  };

  const renderStepButtons = () => {
    return sequence.map((step, i) => {
      return (
        <StepButton
          key={i}
          id={i}
          color={colors[sequence[i]]}
          step={sequence[i]}
          isSelected={selectedStep === i}
          isCurrent={isCurrentStep(i)}
          onClickStepButton={() => handleStepButtonClick(i)}
          onRightClickStepButton={e => handleStepButtonRightClick(e, i)}
          onKeyUp={handleKeyUp}
        />
      );
    });
  };

  return (
    <div className="sequence-container">
      <div className="sequencer">{renderStepButtons()}</div>
    </div>
  );
};

Sequencer.propTypes = {
  id: PropTypes.number,
  colors: PropTypes.array,
  sample: PropTypes.object,
  regions: PropTypes.array,
  numberOfSteps: PropTypes.number,
  selectedStep: PropTypes.number,
  playbackStatus: PropTypes.string,
  songPlaybackStatus: PropTypes.string,
  currentStep: PropTypes.number,
  currentSequenceStep: PropTypes.number,
  onUpdateSelectedStep: PropTypes.func,
  onUpdatePattern: PropTypes.func,
  onUpdateBeats: PropTypes.func
};

export default Sequencer;
