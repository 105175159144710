import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const FileUploader = ({
  colors,
  id,
  isUploading,
  onUploadFile,
  onChangeUploading
}) => {
  const [filename, setFilename] = useState(undefined);
  const fileInput = useRef(null);

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const handleFileSelected = async e => {
    const file = e.target.files[0];

    setFilename(file.name);
    onChangeUploading(true);
    await onUploadFile(file, id);
    onChangeUploading(false);
  };

  const renderInput = () => {
    return (
      <div className="file-uploader">
        <p>Select a file to upload</p>
        <button
          style={{ backgroundColor: colors[4] }}
          onClick={() => openFileBrowser()}
        >
          Browse Files
        </button>
        <input
          className="hidden"
          ref={fileInput}
          type="file"
          onChange={handleFileSelected}
        />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="loading-container">
        <p>Uploading {filename}...</p>
      </div>
    );
  };

  return (
    <>
      {!isUploading && renderInput()}
      {isUploading && renderLoading()}
    </>
  );
};

FileUploader.propTypes = {
  colors: PropTypes.array,
  id: PropTypes.number,
  isUploading: PropTypes.bool,
  onUploadFile: PropTypes.func,
  onChangeUploading: PropTypes.func
};

export default FileUploader;
