import React, { useState } from "react";
import PropTypes from "prop-types";

import { login } from "../services/auth-service";
import { songsByUser } from "../services/songs-service";

import { generateSlug, generateShareUrl } from "../utils/song-utils";

const Share = ({
  colors,
  userId,
  jwtToken,
  song,
  onUpdateSlug,
  onCloseTray,
}) => {
  const [slug, setSlug] = useState(generateSlug(song.slug, song.title));
  const handleSlugInputChange = (e) => {
    setSlug(e.target.value);
  };

  const handleShareUrlFormSubmit = async (e) => {
    e.preventDefault();

    onUpdateSlug(generateSlug(null, slug));
  };

  return (
    <>
      <form onSubmit={handleShareUrlFormSubmit}>
        {song.slug && (
          <>
            <p>Use this URL to share your song with friends:</p>
            <input
              className="share-url-input"
              disabled={true}
              value={generateShareUrl(userId, song.slug)}
            />
            <button
              style={{ backgroundColor: colors[5] }}
              onClick={onCloseTray}
            >
              OK, Thanks!
            </button>
          </>
        )}
        <hr />
        <p>You can change your Share URL below.</p>
        <p>Alpha-numerical characters and dashes only.</p>
        <div className="share-url">
          <p>{`${process.env.BASE_URL_SHORT}/users/${userId}/share/`}</p>
          <input
            type="text"
            value={slug}
            placeholder="share url"
            onChange={handleSlugInputChange}
          />
        </div>
        <button style={{ backgroundColor: colors[1] }}>Change Share URL</button>
      </form>
    </>
  );
};

Share.propTypes = {
  colors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  song: PropTypes.object,
  onUpdateSlug: PropTypes.func,
  onCloseTray: PropTypes.func,
};

export default Share;
