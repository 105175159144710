import React, { useState } from "react";
import PropTypes from "prop-types";

import { login } from "../services/auth-service";
import { songsByUser } from "../services/songs-service";

const Login = ({ colors, userId, jwtToken, onLogin, onCloseTray }) => {
  const [email, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);

  const handleEmailInputChange = e => {
    setEmailAddress(e.target.value);
  };

  const handlePasswordInputChange = e => {
    setPassword(e.target.value);
  };

  const handleLoginFormSubmit = async e => {
    e.preventDefault();

    const { id, token } = await login(email, password);

    if (!id) {
      setLoginFailed(true);
    }

    let songs;
    if (id) {
      songs = await songsByUser(token, id);
    }

    onLogin({ id, songs }, token);
  };

  return (
    <>
      {!userId && !jwtToken && !loginFailed && (
        <form className="signup-form" onSubmit={handleLoginFormSubmit}>
          <p>Log in to access your saved songs.</p>
          <input
            type="text"
            value={email}
            placeholder="email address"
            onChange={handleEmailInputChange}
          />
          <input
            type="password"
            value={password}
            placeholder="password"
            onChange={handlePasswordInputChange}
          />
          <button style={{ backgroundColor: colors[7] }}>Log in</button>
        </form>
      )}
      {userId && jwtToken && !loginFailed && (
        <div className="signup-form">
          <p>Logged in succesfully!</p>
          <button style={{ backgroundColor: colors[1] }} onClick={onCloseTray}>
            OK, Thanks!
          </button>
        </div>
      )}
      {loginFailed && (
        <div className="signup-form">
          <p>Logged in failed, incorrect email or password</p>
          <button
            style={{ backgroundColor: colors[1] }}
            onClick={() => setLoginFailed(false)}
          >
            Try again.
          </button>
        </div>
      )}
    </>
  );
};

Login.propTypes = {
  colors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  onLogin: PropTypes.func,
  onCloseTray: PropTypes.func
};
export default Login;
