export default class Audio {
  constructor(audio) {
    this.audio = audio;
  }

  create(container, filepath) {
    return audio;
  }

  on(eventName, callback) {
    return this.audio.on(eventName, callback);
  }

  play(atMs) {
    return this.audio.play(atMs);
  }

  seekTo(atMs) {
    return this.audio.seekTo(atMs);
  }

  pause() {
    return this.audio.pause();
  }

  stop() {
    this.audio.pause();
    this.seekTo(0);
  }

  duration() {
    return this.audio.getDuration();
  }

  getCurrentTime() {
    return this.audio.getCurrentTime();
  }

  get regions() {
    return this.audio.regions.list;
  }

  get regionKeys() {
    return Object.entries(this.regions);
  }

  getRegionByIndex(regionId) {
    const regionKey = this.regionKeys[regionId][0];
    return this.regions[regionKey];
  }

  setVolume(value) {
    return this.audio.setVolume(value / 100);
  }

  setPlaybackSpeed(value) {
    let speed;
    if (value < 0) {
      const values = [0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1];
      speed = values[value * -1];
    } else if (value == 0) {
      speed = 0;
    } else if (value > 0) {
      speed = value;
    }
    return this.audio.setPlaybackRate(speed);
  }

  filterFilter(filters) {
    return filters.filter(
      filter => filter.constructor.name != "BiquadFilterNode"
    );
  }

  filterPanner(filters) {
    return filters.filter(
      filter => filter.constructor.name != "StereoPannerNode"
    );
  }

  createFilter() {
    return this.audio.backend.ac.createBiquadFilter();
  }

  setFilter(value) {
    let filter = this.createFilter();
    const adjustedValue = value + 50;
    if (adjustedValue < 50) {
      filter.type = "lowpass";
      filter.frequency.value = adjustedValue * 30;
    } else if (adjustedValue === 50) {
      filter.type = "allpass";
      filter.frequency.value = 20000;
    } else if (adjustedValue > 50) {
      filter.type = "highpass";
      filter.frequency.value = adjustedValue ** 1.5;
    }
    const allFilters = this.audio.getFilters();
    const filters = this.filterFilter(allFilters);
    filters.push(filter);
    return this.audio.backend.setFilters(filters);
  }

  setPan(value) {
    this.audio.panner = this.audio.backend.ac.createStereoPanner();
    this.audio.panner.pan.value = value / 100;

    const allFilters = this.audio.getFilters();
    const filters = this.filterPanner(allFilters);
    filters.push(this.audio.panner);

    this.audio.backend.setFilters(filters);
  }

  clearRegions() {
    return this.audio.clearRegions();
  }

  addRegion(region) {
    return this.audio.addRegion(region);
  }

  destroy() {
    return this.audio.destroy();
  }
}
