import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const SliderVertical = ({
  label,
  min,
  max,
  start,
  value,
  scrolled,
  onUpdateScrolled,
  onClickSlider,
  onUpdateSelectedSampleId
}) => {
  const [mousedown, setMousedown] = useState(false);
  const [mouseover, setMouseover] = useState(false);
  const [containerTop, setContainerTop] = useState(0);
  const [containerBottom, setContainerBottom] = useState(0);
  const [allowTextInput, setAllowTextInput] = useState(false);
  const [previewValue, setPreviewValue] = useState(value);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const updateContainerBottom = () => {
    setContainerBottom(containerRef.current.getClientRects()[0].bottom);
  };

  const updateContainerTop = () => {
    setContainerTop(containerRef.current.getClientRects()[0].top);
  };

  const handleMouseOver = e => {
    setMouseover(true);
  };

  const handleMouseDown = e => {
    if (e.button === 2) return;

    setMousedown(true);
    const value = getSliderValue(e.clientY);
    onClickSlider(value);
    setAllowTextInput(false);

    if (previewValue != value) {
      commitPreviewValue();
    }
  };

  const handleMouseMove = e => {
    if (mousedown === false) return;
    const value = getSliderValue(e.clientY);
    onClickSlider(value);
  };

  const handleMouseUp = e => {
    if (mousedown) setMousedown(false);
  };

  const handleMouseLeave = e => {
    if (mousedown) {
      setMousedown(false);
    }
    setMouseover(false);
  };

  const handleRightClick = e => {
    e.preventDefault();

    setPreviewValue(value);
    onUpdateSelectedSampleId(null);
    setAllowTextInput(true);

    inputRef.current.focus();
    inputRef.current.select();

    return false;
  };

  const handleKeyUp = e => {
    if (e.which === 13) {
      commitPreviewValue();
    }
  };

  const handleBlur = e => {
    commitPreviewValue();
  };

  const commitPreviewValue = () => {
    inputRef.current.blur();
    const value = parseInt(inputRef.current.value);

    onClickSlider(value);
    setAllowTextInput(false);
  };

  const numberOfTicks = () => {
    if (min < 0) {
      return Math.abs(min) + max;
    }
    return max - min;
  };

  const getSliderValue = sliderY => {
    const actualRange = _.range(min, max, 1);
    const sliderHeight = containerBottom - containerTop;
    const clickY = sliderY - containerTop;
    const percentageValue = (100 - clickY) / 100;

    const decimalIndex = numberOfTicks() * percentageValue;
    let index = Math.ceil(decimalIndex);

    if (index >= actualRange.length) index = actualRange.length - 1;
    return actualRange[index];
  };

  const valueAsPercentage = () => {
    const actualRange = _.range(min, max, 1);

    let currentValue = value;
    if (isNaN(value)) {
      currentValue = start;
    }

    let percentageFilled;
    if (min === 0 && currentValue === 0) {
      percentageFilled = 0;
    } else if (min < 0) {
      const positiveValue = min * -1 + currentValue;
      const percentage = positiveValue / numberOfTicks();
      const index = Math.floor(numberOfTicks() * percentage);
      percentageFilled = (positiveValue / numberOfTicks()) * 100;
    } else {
      percentageFilled = (currentValue / numberOfTicks()) * 100;
    }
    return `${Math.floor(percentageFilled)}%`;
  };

  const handleInputChange = e => {
    let value = inputRef.current.value;
    let parsedValue = parseInt(value);
    if (value === "-") {
      value = value;
    } else if (parsedValue >= max) {
      value = max - 1;
    } else if (parsedValue < min || isNaN(parsedValue)) {
      value = min;
    }

    setPreviewValue(value);
  };

  const displayValue = () => {
    if (allowTextInput) {
      return previewValue;
    } else {
      return value;
    }
  };

  const sliderClassName = () => {
    let className = "slider-vertical";
    if (allowTextInput) {
      className += " slider-vertical-allow-text-input";
    }
    return className;
  };

  const fillClassName = () => {
    let className = "slider-vertical-fill";
    if (allowTextInput) {
      className += " slider-vertical-fill-allow-text-input";
    } else if (mouseover) {
      className += " slider-vertical-fill-hover";
    }
    return className;
  };

  const percentageFilled = valueAsPercentage();

  useEffect(() => {
    updateContainerTop();
    updateContainerBottom();
    onUpdateScrolled(false);
  }, [scrolled]);

  return (
    <div
      className={sliderClassName()}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleRightClick}
      ref={containerRef}
    >
      <div
        className={fillClassName()}
        style={{ height: percentageFilled }}
      ></div>
      <input
        className="slider-vertical-input"
        value={displayValue()}
        ref={inputRef}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />
      <div className="slider-vertical-label">{label}</div>
    </div>
  );
};

SliderVertical.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  start: PropTypes.number,
  value: PropTypes.number,
  scrolled: PropTypes.bool,
  onUpdateScrolled: PropTypes.func,
  onClickSlider: PropTypes.func,
  onUpdateSelectedSampleId: PropTypes.func
};

export default SliderVertical;
