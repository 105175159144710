import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SliderVertical from "./slider-vertical";

import { debounce } from "../utils/time-utils";

const Controls = ({
  volume,
  numberOfChops,
  numberOfSteps,
  playbackSpeed,
  filter,
  pan,
  onUpdateVolume,
  onUpdateNumberOfChops,
  onUpdateNumberOfSteps,
  onUpdatePlaybackSpeed,
  onUpdateFilter,
  onUpdatePan,
  onUpdateSelectedSampleId
}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      setScrolled(true);
    }, 500);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  });

  return (
    <div className="controls">
      <SliderVertical
        label="Volume"
        min={0}
        max={101}
        start={50}
        value={volume}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdateVolume}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderVertical
        label="Chops"
        min={1}
        max={73}
        start={26}
        value={numberOfChops}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdateNumberOfChops}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderVertical
        label="Steps"
        min={1}
        max={17}
        start={12}
        value={numberOfSteps}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdateNumberOfSteps}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderVertical
        label="Playback Speed"
        min={-9}
        max={16}
        start={0}
        value={playbackSpeed}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdatePlaybackSpeed}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderVertical
        label="Filter"
        min={-50}
        max={51}
        start={0}
        value={filter}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdateFilter}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderVertical
        label="Pan"
        min={-103}
        max={101}
        start={0}
        value={pan}
        scrolled={scrolled}
        onUpdateScrolled={setScrolled}
        onClickSlider={onUpdatePan}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
    </div>
  );
};

Controls.propTypes = {
  volume: PropTypes.number,
  numberOfChops: PropTypes.number,
  numberOfSteps: PropTypes.number,
  playbackSpeed: PropTypes.number,
  filter: PropTypes.number,
  pan: PropTypes.number,
  onUpdateVolume: PropTypes.func,
  onUpdateNumberOfChops: PropTypes.func,
  onUpdateNumberOfSteps: PropTypes.func,
  onUpdatePlaybackSpeed: PropTypes.func,
  onUpdateFilter: PropTypes.func,
  onUpdatePan: PropTypes.func,
  onUpdateSelectedSampleId: PropTypes.func
};

export default Controls;
