import React from "react";
import PropTypes from "prop-types";

import SliderHorizontal from "./slider-horizontal";

import { PLAYING_SONG, PAUSED, STOPPED } from "../common/index";

const TransportBar = ({
  dimensions,
  playbackStatus,
  looping,
  tempo,
  numberOfBeats,
  onUpdateSongState,
  onPlaySong,
  onPauseSong,
  onStopSong,
  onLoopSong,
  onUpdateSelectedSampleId,
  onUpdateNumberOfBeats
}) => {
  const handleUpdateTempo = value => {
    onUpdateSongState({ state: { tempo: value } });
  };

  return (
    <div className="transport-bar">
      <button
        onClick={onPlaySong}
        className={
          "play-song transport-bar-button" +
          (playbackStatus === PLAYING_SONG ? " button-success" : "")
        }
        type="button"
        data-icon="a"
      />
      <button
        onClick={onPauseSong}
        className={
          "pause transport-bar-button" +
          (playbackStatus === PAUSED ? " button-success" : "")
        }
        type="button"
        data-icon="d"
      />
      <button
        onClick={onStopSong}
        className="stop transport-bar-button"
        type="button"
        data-icon="b"
      />
      <button
        onClick={onLoopSong}
        className={
          "loop transport-bar-button" + (looping ? " button-success" : "")
        }
        type="button"
        data-icon="c"
      />
      <SliderHorizontal
        dimensions={dimensions}
        label="Tempo"
        min={1}
        max={960}
        start={120}
        value={tempo}
        onClickSlider={handleUpdateTempo}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
      <SliderHorizontal
        dimensions={dimensions}
        label="# Beats"
        min={1}
        max={512}
        start={400}
        value={numberOfBeats}
        onClickSlider={onUpdateNumberOfBeats}
        onUpdateSelectedSampleId={onUpdateSelectedSampleId}
      />
    </div>
  );
};

TransportBar.propTypes = {
  dimensions: PropTypes.object,
  playbackStatus: PropTypes.string,
  looping: PropTypes.bool,
  tempo: PropTypes.number,
  numberOfBeats: PropTypes.number,
  onUpdateSongState: PropTypes.func,
  onPlaySong: PropTypes.func,
  onPauseSong: PropTypes.func,
  onStopSong: PropTypes.func,
  onLoopSong: PropTypes.func,
  onUpdateSelectedSampleId: PropTypes.func,
  onUpdateNumberOfBeats: PropTypes.func
};

export default TransportBar;
