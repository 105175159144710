import React from "react";
import ReactDOM from "react-dom";
import logMessage from "./js/utils/logger";

import LandingPage from "./js/components/landing-page";
// import { DawState } from "./js/state/state";

import "./css/reset.css";
import "./css/style.css";

logMessage("Sequins Booted");

// Needed for Hot Module Replacement
if (typeof module.hot !== "undefined") {
  module.hot.accept(); // eslint-disable-line no-undef
}

//const daw = new Daw(new DawState());

ReactDOM.render(
  <LandingPage />,
  document.querySelector("[data-daw-container]")
);
