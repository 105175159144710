import { options } from "./fetch";

export const createSong = async (jwtToken, userId, title, state) => {
  const data = { title, state };
  const fetchUrl = `${process.env.API_BASE_URL}/api/users/${userId}/songs`;
  const fetchParams = {
    ...options("POST", jwtToken),
    body: JSON.stringify(data),
  };

  console.log(fetchParams);

  return await fetch(fetchUrl, fetchParams)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) console.error(res.error);
      return res;
    });
};

export const songsByUser = async (jwtToken, userId) => {
  const fetchUrl = `${process.env.API_BASE_URL}/api/users/${userId}/songs`;
  const fetchParams = { ...options("GET", jwtToken) };

  console.log(fetchParams);

  return await fetch(fetchUrl, fetchParams)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) console.error(res.error);
      return res;
    });
};

export const updateSong = async (jwtToken, userId, songId, newSong) => {
  const data = newSong;
  const fetchUrl = `${process.env.API_BASE_URL}/api/users/${userId}/songs/${songId}`;
  const fetchParams = {
    ...options("PUT", jwtToken),
    body: JSON.stringify(data),
  };

  console.log(fetchParams);

  return await fetch(fetchUrl, fetchParams)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) console.error(res.error);
      return res;
    });
};

const songsService = {
  createSong,
  songsByUser,
  updateSong,
};

export default songsService;
