import React, { useState, useEffect } from "react";

import Song from "./song.js";

import authService from "../services/auth-service";

import AudioContextContext from "../contexts/audio-context-context";
import buildColors from "../utils/build-colors";
import sequinsLogo from "../common/sequins-logo";

const LandingPage = () => {
  const [audioContext, setAudioContext] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [jwtToken, setJwtToken] = useState(undefined);

  const handleLogin = (user, token) => {
    setUser(user);
    setJwtToken(token);
  };

  const initializeAudioContext = async () => {
    const context = new AudioContext();
    setAudioContext(context);
  };

  const logoColors = buildColors(1, 9, 50, 30);

  useEffect(() => {
    const loginFromCookies = async () => {
      const res = await authService.currentUser(jwtToken);
      if (res) {
        const { user, token } = res;
        if (user && token) {
          handleLogin(user, token);
        }
      }
    };

    loginFromCookies();
  }, []);

  return (
    <>
      {!audioContext && (
        <div className="splash-screen" onClick={initializeAudioContext}>
          <div>
            <img
              className="logo"
              src={`data:image/svg+xml;utf8,${sequinsLogo(logoColors)}`}
            />
            <h1>Sequins</h1>
            <p>Click anywhere to start</p>
            <p>This is an in-progress experiment.</p>
            <p>Please use Chrome, I have not yet tested in other browsers</p>
          </div>
        </div>
      )}
      {audioContext && (
        <AudioContextContext.Provider value={audioContext}>
          <Song
            logoColors={logoColors}
            user={user}
            jwtToken={jwtToken}
            onLogin={handleLogin}
          />
        </AudioContextContext.Provider>
      )}
    </>
  );
};

export default LandingPage;
