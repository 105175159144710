import React, { useState } from "react";
import PropTypes from "prop-types";

import Signup from "./signup";
import Login from "./login";
import Share from "./share";
import FileUploader from "./file-uploader";
import SongEditor from "./song-editor";
import Confirmation from "./confirmation";

const DialogueTray = ({
  colors,
  trayComponent,
  confirmation,
  userId,
  jwtToken,
  song,
  onLogin,
  onFinishSignup,
  onCreateSong,
  onUpdateTitle,
  onUpdateSlug,
  onCloseTray,
  onUploadFile
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={"dialogue-tray" + (isLoading ? " loading" : "")}>
      {trayComponent === SIGNUP && (
        <Signup
          colors={colors}
          userId={userId}
          jwtToken={jwtToken}
          onLogin={onLogin}
          onFinishSignup={onFinishSignup}
          onCloseTray={onCloseTray}
        />
      )}
      {trayComponent === LOGIN && (
        <Login
          colors={colors}
          userId={userId}
          jwtToken={jwtToken}
          onLogin={onLogin}
          onCloseTray={onCloseTray}
        />
      )}
      {trayComponent === SHARE && (
        <Share
          colors={colors}
          userId={userId}
          jwtToken={jwtToken}
          song={song}
          onUpdateSlug={onUpdateSlug}
          onCloseTray={onCloseTray}
        />
      )}
      {trayComponent === SONG_EDITOR && (
        <SongEditor
          colors={colors}
          userId={userId}
          jwtToken={jwtToken}
          song={song}
          onCreateSong={onCreateSong}
          onUpdateTitle={onUpdateTitle}
        />
      )}
      {trayComponent === CONFIRMATION && (
        <Confirmation
          colors={colors}
          confirmation={confirmation}
          onCloseTray={onCloseTray}
        />
      )}
      {trayComponent === FILE_UPLOADER && (
        <FileUploader
          colors={colors}
          isUploading={isLoading}
          onChangeUploading={setIsLoading}
          onUploadFile={onUploadFile}
          onCloseTray={onCloseTray}
        />
      )}
    </div>
  );
};

DialogueTray.propTypes = {
  colors: PropTypes.array,
  trayComponent: PropTypes.string,
  confirmation: PropTypes.string,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  song: PropTypes.object,
  onLogin: PropTypes.func,
  onFinishSignup: PropTypes.func,
  onCreateSong: PropTypes.func,
  onUpdateTitle: PropTypes.func,
  onUpdateSlug: PropTypes.func,
  onCloseTray: PropTypes.func,
  onUploadFile: PropTypes.func
};

export default DialogueTray;
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const SHARE = "SHARE";
export const SONG_EDITOR = "SONG_EDITOR";
export const CONFIRMATION = "CONFIRMATION";
export const FILE_UPLOADER = "FILE_UPLOADER";
