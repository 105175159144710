import {
  REST,
  SEQUENCE_START,
  SEQUENCE,
  SEQUENCE_END,
  SEQUENCE_PREVIEW
} from "../common/index";

import {
  findAllIndices,
  findSequenceStartIndex,
  findSequenceEndIndex
} from "../utils/sequence-utils";

export const buildBeat = ({ patternId, type, regionId }) => {
  return {
    patternId,
    type,
    regionId
  };
};
export const restBeat = buildBeat({ type: REST });

export const previewSequence = ({
  patternId,
  beats,
  startIndex,
  sequenceLength
}) => {
  const newBeats = [...beats];
  const previewBeat = buildBeat({ patternId, type: SEQUENCE_PREVIEW });
  newBeats[startIndex] = previewBeat;

  for (let i = startIndex + 1; i < startIndex + sequenceLength; i++) {
    newBeats[i] = previewBeat;
  }

  return newBeats;
};

export const cancelPreviewSequence = ({
  beats,
  startIndex,
  sequenceLength
}) => {
  const newBeats = [...beats];
  newBeats[startIndex] = restBeat;
  for (let i = startIndex + 1; i < startIndex + sequenceLength; i++) {
    newBeats[i] = restBeat;
  }

  return newBeats;
};

export const addSequence = ({ patternId, beats, startIndex, sequence }) => {
  const newBeats = [...beats];
  newBeats[startIndex] = buildBeat({
    patternId,
    type: SEQUENCE_START,
    regionId: sequence[0]
  });

  for (let i = 1; i < sequence.length; i++) {
    if (i === sequence.length - 1) {
      newBeats[i + startIndex] = buildBeat({
        patternId,
        type: SEQUENCE_END,
        regionId: sequence[sequence.length - 1]
      });
    } else {
      newBeats[i + startIndex] = buildBeat({
        patternId,
        type: SEQUENCE,
        regionId: sequence[i]
      });
    }
  }
  return newBeats;
};

export const removeSequence = ({ beats, startIndex, sequenceLength }) => {
  const newBeats = [...beats];
  newBeats[startIndex] = restBeat;

  for (let i = startIndex + 1; i < startIndex + sequenceLength; i++) {
    newBeats[i] = restBeat;
  }

  return newBeats;
};

export const updateSequences = ({ patternId, beats, sequence }) => {
  const sequenceStartIndices = findAllIndices(beats, SEQUENCE_START);
  let newBeats = [...beats];

  let sequenceDestroyed = false;
  sequenceStartIndices.map((startIndex, i) => {
    // only resize patterns that match the currently selected pattern
    const currentPatternId = beats[startIndex].patternId;

    if (currentPatternId != patternId) return;
    const endIndex = findSequenceEndIndex(beats, startIndex + 1);

    if (sequenceDestroyed) {
      sequenceDestroyed = false;
      return;
    }

    // destroy subsequent sequences overlapped by growing current sequence
    const expectedEndIndex = startIndex + sequence.length - 1;
    if (beats[expectedEndIndex].type === SEQUENCE_START) {
      const nextStartIndex = sequenceStartIndices[i + 1];
      const nextEndIndex = findSequenceEndIndex(beats, nextStartIndex + 1);
      newBeats.fill(restBeat, nextStartIndex, nextEndIndex + 1);
      sequenceDestroyed = true;
    }

    // replace current sequence start with updated SEQUENCE_START beat
    newBeats[startIndex] = buildBeat({
      patternId,
      type: SEQUENCE_START,
      regionId: sequence[0]
    });

    // replace current sequence with updated SEQUENCE beats
    let sequenceIndex = 1;
    for (i = startIndex + 1; i < startIndex + sequence.length - 1; i++) {
      newBeats[i] = buildBeat({
        patternId,
        type: SEQUENCE,
        regionId: sequence[sequenceIndex]
      });
      sequenceIndex++;
    }

    const oldSequenceLength = endIndex - startIndex + 1;

    // if the new pattern is smaller, replace difference with REST beats
    if (oldSequenceLength - sequence.length > 0) {
      newBeats.fill(
        restBeat,
        startIndex + sequence.length,
        startIndex + oldSequenceLength
      );
    }

    // cap current sequence with SEQUENCE_END
    newBeats[startIndex + sequence.length - 1] = buildBeat({
      patternId,
      type: SEQUENCE_END,
      regionId: sequence[sequence.length - 1]
    });
  });

  return newBeats;
};

export const updateNumberOfBeats = ({ beats, numberOfBeats }) => {
  let newBeats = [...beats];
  // increase/decrease # of beats
  if (numberOfBeats > newBeats.length) {
    const diff = numberOfBeats - beats.length;
    for (let i = newBeats.length - 1; i < numberOfBeats; i++) {
      newBeats[i] = restBeat;
    }
  } else if (numberOfBeats < newBeats.length) {
    newBeats = newBeats.slice(0, numberOfBeats);

    // destroy sequences cut off by trimming beats
    if (![REST, SEQUENCE_END].includes(newBeats[numberOfBeats - 1].type)) {
      const startIndex = findSequenceStartIndex(newBeats, numberOfBeats - 1);
      for (let i = startIndex; i < newBeats.length; i++) {
        newBeats[i] = restBeat;
      }
    }
  }

  return newBeats;
};

export const sequenceFits = ({ beats, startIndex, sequenceLength }) => {
  let fits = true;
  for (let i = 0; i < sequenceLength; i++) {
    const nextBeat = beats[startIndex + i];
    if (
      isOutOfRange(startIndex + i, beats.length) ||
      ![REST, SEQUENCE_PREVIEW].includes(nextBeat.type)
    ) {
      fits = false;
    }
  }
  return fits;
};

export const isSequenceBeat = type => {
  return [SEQUENCE_START, SEQUENCE, SEQUENCE_END].includes(type);
};

export const isFinalBeat = (currentBeatIndex, numberOfBeats) => {
  return currentBeatIndex === numberOfBeats - 1;
};

export const isOutOfRange = (currentBeatIndex, numberOfBeats) => {
  return currentBeatIndex >= numberOfBeats;
};
