import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import NavigationBar from "./navigation-bar";
import TransportBar from "./transport-bar";
import DialogueTray from "./dialogue-tray";
import Samples from "./samples";
import Tracks from "./tracks";

import {
  SIGNUP,
  LOGIN,
  SHARE,
  SONG_EDITOR,
  CONFIRMATION,
  FILE_UPLOADER,
} from "./dialogue-tray";
import {
  SONG_SAVED,
  SONG_TITLE_UPDATED,
  SAMPLE_ADDED,
  SHARE_URL_UPDATED,
} from "./confirmation";
import { REST, PLAYING_SONG, PAUSED, STOPPED } from "../common/index";

import uploadFile from "../services/uploads-service";

import incrementId from "../utils/increment-id";
import buildColors from "../utils/build-colors";
import { updateNumberOfBeats, isFinalBeat } from "../utils/beat-utils";
import { getFilename } from "../utils/file-utils";
import { debounce } from "../utils/time-utils";

const Daw = ({
  logoColors,
  userId,
  jwtToken,
  onLogin,
  song,
  onCreateSong,
  onUpdateTitle,
  onUpdateSongState,
  onSaveSong,
  onAddSample,
  onUpdateSample,
  onDeleteSample,
  onUpdateSlug,
  onUpdatePattern,
  onDeletePattern,
  onUpdateBeats,
}) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const { title, state } = song;
  const { tempo, numberOfBeats, samples } = state;

  const regionColorsTransparent = buildColors(0.5, 72, 50, 30);
  const regionColorsSolid = buildColors(1, 72, 50, 30);
  const darkColors = buildColors(0.5, samples.length, 30, 30);
  const lightColors = buildColors(0.9, samples.length, 50, 30);

  const msPerStep = 60000 / tempo / 4;
  const [playbackStatus, setPlaybackStatus] = useState(STOPPED);
  const [looping, setLooping] = useState(false);
  const [loopFinished, setLoopFinished] = useState(false);
  const [currentBeat, setCurrentBeat] = useState(0);
  const [markerSet, setMarkerSet] = useState(false);
  const [lastPatternId, setLastPatternId] = useState(undefined);
  const [selectedSampleId, setSelectedSampleId] = useState(0);

  const [trayComponent, setTrayComponent] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const toggleTrayComponent = (component) => {
    if (trayComponent === component) {
      setTrayComponent(null);
    } else {
      setTrayComponent(component);
    }
  };

  const playSequence = async ({ sample, beat }) => {
    const { id, audio, patterns, currentSequenceStep } = sample;
    const patternId = beat.patternId;
    const pattern = patterns[beat.patternId];
    const sequence = pattern.sequence;

    setLastPatternId(patternId);
    if (patternId != lastPatternId) {
      audio.setFilter(pattern.filter);
      audio.setPan(pattern.pan);
      audio.setPlaybackSpeed(pattern.playbackSpeed);
    }

    if (sequence[currentSequenceStep] != REST) {
      const region = audio.getRegionByIndex(sequence[currentSequenceStep]);
      region.play();
    }

    if (currentSequenceStep === sequence.length - 1) {
      onUpdateSample({ id, currentSequenceStep: 0 });
    } else {
      onUpdateSample({
        id,
        currentSequenceStep: currentSequenceStep + 1,
      });
    }
  };

  const playSong = async () => {
    setPlaybackStatus(PLAYING_SONG);
    const finalBeat = isFinalBeat(currentBeat, numberOfBeats);

    await playBeat();

    if (finalBeat && looping) {
      setCurrentBeat(0);
      setLoopFinished(true);
    } else if (finalBeat && !looping) {
      stopSong();
    } else {
      setCurrentBeat(currentBeat + 1);
    }
  };

  const playBeat = (e) => {
    setPlaybackStatus(PLAYING_SONG);

    samples.map((sample) => {
      const beat = sample.beats[currentBeat];
      if (beat.type === REST) {
        samples[sample.id].audio.stop();
      } else {
        playSequence({ sample, beat });
      }
    });

    return new Promise((resolve) => {
      setTimeout(resolve, msPerStep);
    });
  };

  const pauseSong = (e) => {
    setPlaybackStatus(PAUSED);
  };

  const resetCurrentSequenceSteps = () => {
    samples.map(({ id }) => onUpdateSample({ id, currentSequenceStep: 0 }));
  };

  const stopSong = (e) => {
    setPlaybackStatus(STOPPED);
    setCurrentBeat(0);
  };

  const loopSong = (e) => {
    setLooping(!looping);
  };

  const handleSampleClick = (id) => {
    setSelectedSampleId(id);
  };

  const handleUploadFile = async (file) => {
    const id = incrementId(samples);
    const filepath = await uploadFile(jwtToken, userId, song.id, file);
    const filename = getFilename(filepath);

    const newSample = { id, filepath };

    onAddSample(newSample);
    setTrayComponent(CONFIRMATION);
    setConfirmation(SAMPLE_ADDED(filename));
    setSelectedSampleId(id);
  };

  const handleAddSampleButtonClick = (e) => {
    toggleTrayComponent(FILE_UPLOADER);
  };

  const handleSaveButtonClick = (e) => {
    if (!userId && !jwtToken) {
      toggleTrayComponent(SIGNUP);
    } else if (!title) {
      toggleTrayComponent(SONG_EDITOR);
    } else {
      onSaveSong();
      toggleTrayComponent(CONFIRMATION);
      setConfirmation(SONG_SAVED);
    }

    setSelectedSampleId(null);
  };

  const handleFinishSignup = () => {
    setTrayComponent(SONG_EDITOR);
  };

  const handleCreateSong = (title) => {
    onCreateSong(title);
    setTrayComponent(CONFIRMATION);
    setConfirmation(SONG_SAVED);
  };

  const handleEditSongButtonClick = (e) => {
    toggleTrayComponent(SONG_EDITOR);
    setSelectedSampleId(null);
  };

  const handleUpdateTitle = (title) => {
    onUpdateTitle(title);
    setTrayComponent(CONFIRMATION);
    setConfirmation(SONG_TITLE_UPDATED);
  };

  const handleUpdateSlug = (slug) => {
    onUpdateSlug(slug);
  };

  const handleShareButtonClick = (e) => {
    toggleTrayComponent(SHARE);
    setSelectedSampleId(null);
  };

  const handleLoginButtonClick = (e) => {
    trayComponent === LOGIN ? setTrayComponent(null) : setTrayComponent(LOGIN);
    setSelectedSampleId(null);
  };

  const handleUpdateNumberOfBeats = (numberOfBeats) => {
    onUpdateSongState({ state: { numberOfBeats } });
    samples.map((sample, i) => {
      const { beats } = sample;
      const newBeats = updateNumberOfBeats({ beats, numberOfBeats });
      onUpdateBeats({ sampleId: sample.id, newBeats });
    });
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  useEffect(() => {
    if (
      playbackStatus === PLAYING_SONG &&
      currentBeat === 0 &&
      looping &&
      loopFinished
    ) {
      playSong();
      setLoopFinished(false);
    } else if (playbackStatus === PLAYING_SONG && currentBeat > 0) {
      playSong();
    } else if (playbackStatus === STOPPED && currentBeat != 0 && !markerSet) {
      setCurrentBeat(0);
      resetCurrentSequenceSteps();
      setLoopFinished(false);
    }
  }, [playbackStatus, currentBeat, loopFinished]);

  return (
    <>
      <NavigationBar
        logoColors={logoColors}
        userId={userId}
        jwtToken={jwtToken}
        song={song}
        onShowLogin={handleLoginButtonClick}
        onShowShare={handleShareButtonClick}
        onShowSignup={handleSaveButtonClick}
        onShowSongEditor={handleEditSongButtonClick}
        onShowSongSaved={handleSaveButtonClick}
        onShowFileUploader={handleAddSampleButtonClick}
      />
      <TransportBar
        dimensions={dimensions}
        playbackStatus={playbackStatus}
        looping={looping}
        tempo={tempo}
        numberOfBeats={numberOfBeats}
        onUpdateSongState={onUpdateSongState}
        onPlaySong={playSong}
        onPauseSong={pauseSong}
        onStopSong={stopSong}
        onLoopSong={loopSong}
        onUpdateSelectedSampleId={setSelectedSampleId}
        onUpdateNumberOfBeats={handleUpdateNumberOfBeats}
      />
      <div className="navigation-bar-spacer"></div>
      {trayComponent && (
        <DialogueTray
          colors={logoColors}
          trayComponent={trayComponent}
          confirmation={confirmation}
          userId={userId}
          jwtToken={jwtToken}
          song={song}
          onLogin={onLogin}
          onFinishSignup={handleFinishSignup}
          onCreateSong={handleCreateSong}
          onUpdateTitle={handleUpdateTitle}
          onUpdateSlug={handleUpdateSlug}
          onCloseTray={() => setTrayComponent(null)}
          onUploadFile={handleUploadFile}
        />
      )}
      <Samples
        darkColors={darkColors}
        regionColorsTransparent={regionColorsTransparent}
        regionColorsSolid={regionColorsSolid}
        songId={song.id}
        samples={samples}
        selectedSampleId={selectedSampleId}
        msPerStep={msPerStep}
        songPlaybackStatus={playbackStatus}
        onClickSample={handleSampleClick}
        onUpdateSample={onUpdateSample}
        onDeleteSample={onDeleteSample}
        onUpdatePattern={onUpdatePattern}
        onDeletePattern={onDeletePattern}
        onUpdateSelectedSampleId={setSelectedSampleId}
        onUpdateBeats={onUpdateBeats}
      />
      <div id="song-grid">
        <Tracks
          lightColors={lightColors}
          darkColors={darkColors}
          regionColors={regionColorsSolid}
          samples={samples}
          numberOfBeats={numberOfBeats}
          currentBeat={currentBeat}
          onUpdateSample={onUpdateSample}
        />
      </div>
    </>
  );
};

Daw.propTypes = {
  logoColors: PropTypes.array,
  userId: PropTypes.number,
  jwtToken: PropTypes.string,
  onLogin: PropTypes.func,
  song: PropTypes.object,
  onCreateSong: PropTypes.func,
  onUpdateTitle: PropTypes.func,
  onUpdateSongState: PropTypes.func,
  onSaveSong: PropTypes.func,
  onAddSample: PropTypes.func,
  onUpdateSample: PropTypes.func,
  onDeleteSample: PropTypes.func,
  onUpdateSlug: PropTypes.func,
  onUpdatePattern: PropTypes.func,
  onDeletePattern: PropTypes.func,
  onUpdateBeats: PropTypes.func,
};

export default Daw;
