import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import buildColors from "../utils/build-colors";

import { REST } from "../common/index";

const PatternButton = ({
  regionColors,
  id,
  sampleId,
  selectedPatternId,
  sequence,
  numberOfChops,
  playbackStatus,
  onUpdateSample,
  onDeletePattern,
  onStop
}) => {
  const handleUpdateSelectedPattern = e => {
    onStop();
    onUpdateSample({ id: sampleId, selectedPatternId: id });
  };

  const generatePatternBackgroundGradient = sequence => {
    const regionColors = buildColors(1, numberOfChops, 50, 30);
    let style = "linear-gradient(90deg, ";

    for (let i = 0; i < sequence.length; i++) {
      const index = sequence[i];
      let color = "transparent";
      if (index != REST) color = regionColors[index];

      const barStart = `${color} ${Math.ceil((i / sequence.length) * 100)}%,`;

      style += barStart;

      const barEnd = `${color} ${Math.ceil(
        ((i + 1) / sequence.length) * 100
      )}%,`;

      style += barEnd;
    }

    const lastIndex = sequence[sequence.length - 1];
    let lastColor = "#fff";

    if (lastIndex != REST) lastColor = regionColors[sequence[lastIndex]];
    style += `${lastColor} 100%)`;

    return { background: style };
  };

  return (
    <button
      className={`pattern-tab ${
        selectedPatternId === id ? "pattern-tab-selected" : ""
      }`}
      onClick={handleUpdateSelectedPattern}
      onContextMenu={onDeletePattern}
    >
      <span className="pattern-tab-name">Pattern {id + 1}</span>
      <div
        className="pattern-tab-bars"
        style={generatePatternBackgroundGradient(sequence)}
      ></div>
    </button>
  );
};

PatternButton.propTypes = {
  regionColors: PropTypes.array,
  id: PropTypes.number,
  sampleId: PropTypes.number,
  selectedPatternId: PropTypes.number,
  sequence: PropTypes.array,
  numberOfChops: PropTypes.number,
  playbackStatus: PropTypes.string,
  onUpdateSample: PropTypes.func,
  onDeletePattern: PropTypes.func,
  onStop: PropTypes.func
};

export default PatternButton;
